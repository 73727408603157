import {useTranslation} from 'react-i18next';

import {ProfileCell} from '../styles';
import {P} from '@quarks';
import {BookingPreferencesModal} from '../BookingPreferencesModal';
import {useModal} from '@hooks';

import {StyledIcon, StyledClickableCellWrapper} from './styles';
import {TwoColumnCell} from '@atoms';
import {pxToRem} from '@utils';

export const BookingPreferencesCell = () => {
  const {t} = useTranslation();
  const {addPages, openModal} = useModal();

  const handleEditWorkingHours = () => {
    addPages([<BookingPreferencesModal />]);
    openModal();
  };

  return (
    <ProfileCell>
      <StyledClickableCellWrapper onClick={handleEditWorkingHours}>
        <TwoColumnCell
          padding="small"
          alignItems="center"
          left={<P>{t('translation:BookingPreferences')}</P>}
          right={
            <StyledIcon
              icon="caretRight"
              size={pxToRem(24)}
            />
          }
        />
      </StyledClickableCellWrapper>
    </ProfileCell>
  );
};
