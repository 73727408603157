import {useTranslation} from 'react-i18next';

import {
  getIsOnboardingResetEnabled,
  getUserEmail,
  getUserFriendlyName,
  getUserImageHash,
  getUserInitials,
  setIsNew,
  setIsSigningOut,
  signOut,
} from '@lib/store';

import {useAppSelector, useAppDispatch, useModal} from '@hooks';

import {FlexRow, H2, P} from '@quarks';
import {Avatar, Button, PageHeader} from '@molecules';
import {AboutCard, PreferencesCard, ExtrasCard, ExportsCard, ShiftsCard, ProfilePictureModal} from '@organisms';
import {Canvas, Content, Heading} from '@templates';
import {CircularProfilePicButton, IconOverlay} from './styles';
import {useEffect} from 'react';
import {trackPageComponentedRendered} from '@lib/infrastructure';

export const Profile = () => {
  const dispatch = useAppDispatch();

  const logOut = () => {
    dispatch(setIsSigningOut(true));
    dispatch(signOut());
  };

  const isOnboardingResetEnabled = useAppSelector(getIsOnboardingResetEnabled);
  const imageHash = useAppSelector(getUserImageHash);
  const userName = useAppSelector(getUserFriendlyName);
  const email = useAppSelector(getUserEmail);
  const initials = useAppSelector(getUserInitials);

  const {addPages, openModal} = useModal();
  const {t} = useTranslation();

  const handleAvatarClick = () => {
    addPages([<ProfilePictureModal />]);
    openModal();
  };

  useEffect(() => {
    trackPageComponentedRendered('profile', true);
  }, []);

  return (
    <>
      <Canvas />
      <Heading>
        <PageHeader title={t('screen:Profile')} />
      </Heading>

      <Content>
        <FlexRow
          alignItems="center"
          sm={{padding: '0 16px'}}>
          <FlexRow
            alignItems="center"
            gap={16}>
            <CircularProfilePicButton
              onClick={handleAvatarClick}
              aria-label={t('screen:YourProfilePhoto')}>
              <Avatar
                user={{imageHash, email, name: userName, initials}}
                size="xlarge"
              />
              <IconOverlay
                size="1.5rem"
                icon="camera"
                color="currentColor"
              />
            </CircularProfilePicButton>
            <H2 as="p">{userName}</H2>
          </FlexRow>
          <Button
            data-testid="pages-profile-signOut-button"
            button="secondary destructive"
            size="small"
            onClick={logOut}>
            {t('profile:SignOut')}
          </Button>
        </FlexRow>

        <ShiftsCard card="white" />

        <ExtrasCard card="white" />

        <ExportsCard card="white" />

        <PreferencesCard card="white" />

        <AboutCard card="white" />

        {isOnboardingResetEnabled && (
          <div>
            <P>Debug:</P>

            <Button
              button="primary"
              onClick={() => {
                dispatch(setIsNew(true));
              }}>
              Reset onboarding
            </Button>
          </div>
        )}
      </Content>
    </>
  );
};
