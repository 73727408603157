import {createPortal} from 'react-dom';
import {useTransition} from 'react-spring';

import {useDialog, useModal} from '@hooks';

import {ModalBackground, SubPageAutoControl} from '@organisms';
import {ModalCenterWrap} from '@templates';

import FocusTrap from 'focus-trap-react';
import {useEffect, useRef} from 'react';
import {StyledCard, StyledModalLayout} from './styles';

const modalRoot = document.getElementById('modal-portal')!;
export const Modal = () => {
  const {isModalOpen, setModalPages, modalPages, activePageIsBig, onCloseCallback, setOnCloseCallback, closeModal} =
    useModal();
  const {
    state: {isDialogOpen},
  } = useDialog();

  // Keep track of the "real" isModalOpen value from context so we have access to it
  // in the onRest callback
  const sharedIsModalOpen = useRef(false);
  useEffect(
    function trackSharedIsModalOpen() {
      sharedIsModalOpen.current = isModalOpen;
    },
    [isModalOpen],
  );

  const removeOwnPages = () => {
    setModalPages((currentPages) => {
      const pagesWithoutClosedStack = currentPages.filter((page) => !modalPages.includes(page));
      return pagesWithoutClosedStack;
    });
  };

  const resetState = () => {
    setOnCloseCallback(false);
    setModalPages([]);
  };

  const transition = useTransition(isModalOpen, {
    from: {transform: 'translate3d(0, 100px, 0)', opacity: 0},
    enter: {transform: 'translate3d(0, 0px, 0)', opacity: 1},
    leave: {transform: 'translate3d(0, 100px, 0)', opacity: 0},
    onRest: () => {
      const isClosingAnimation = !isModalOpen;
      if (isClosingAnimation) {
        const noNewModalOpened = !sharedIsModalOpen.current;

        if (noNewModalOpened) resetState();
        else removeOwnPages();
      }
    },
  });

  const focusTrapOptions = {
    allowOutsideClick: true,
    clickOutsideDeactivates: true,
    escapeDeactivates: false,
  };

  const handleClickOutside = () => {
    if (isDialogOpen) return;

    if (typeof onCloseCallback !== 'boolean') {
      onCloseCallback();
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    const handleKeyDown = ({key}: KeyboardEvent) => {
      if (key === 'Escape') {
        if (isDialogOpen) return;

        if (typeof onCloseCallback !== 'boolean') {
          onCloseCallback();
        } else {
          closeModal();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [onCloseCallback, closeModal, isDialogOpen]);

  const dimensions = activePageIsBig ? {'--modalPreferredWidth': `100vw`, '--modalHeight': `100vh`} : {};

  return createPortal(
    <>
      <ModalBackground
        onClick={handleClickOutside}
        clickable={true}
        open={isModalOpen}
      />
      {transition(
        (style, item) =>
          item && (
            <ModalCenterWrap>
              <FocusTrap focusTrapOptions={focusTrapOptions}>
                <StyledModalLayout
                  role="dialog"
                  style={{...style, ...dimensions}}>
                  <StyledCard>
                    <SubPageAutoControl pages={modalPages} />
                  </StyledCard>
                </StyledModalLayout>
              </FocusTrap>
            </ModalCenterWrap>
          ),
      )}
    </>,
    modalRoot,
  );
};
