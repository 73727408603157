import {CalendarWorkspaceTileProps} from './types';

import {
  useAppDispatch,
  useAppSelector,
  getIsLoadingWorkspaceReservations,
  getIsWorkdayLoading,
  getIsWorkspaceReservationButtonEnabled,
  getWorkspaceReservationByDate,
} from '@lib/store';
import {useModal} from '@hooks';
import {parseISO} from 'date-fns';

import {SquareButton} from '@molecules';
import {BookWorkspaceCard, CalendarWorkspaceTileBookingInfo, BookingDetailsCard} from '@organisms';
import {trackEvent} from '@lib/infrastructure';
import {Loader} from '@atoms';

export const CalendarWorkspaceTile = ({date}: CalendarWorkspaceTileProps) => {
  const dispatch = useAppDispatch();

  const {addPages, openModal} = useModal();
  const dateAsDate = parseISO(date);
  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, dateAsDate));
  const workDayPending = useAppSelector(getIsWorkdayLoading);
  const workspacePending = useAppSelector(getIsLoadingWorkspaceReservations);
  // TODO: add this && case to the UI selector
  const isEnabled =
    useAppSelector((state) => getIsWorkspaceReservationButtonEnabled(state, dateAsDate)) && !workDayPending;

  const isGroupBooking = !!workspaceReservation?.groupReservationId;

  const handleOnClick = () => {
    if (workspaceReservation) {
      addPages([
        <BookingDetailsCard
          date={date}
          workspaceReservation={workspaceReservation}
        />,
      ]);
      openModal();

      trackEvent('CalendarOverview_BookingDetails__Open', {
        date,
        isGroupBooking,
      });
    } else {
      if (!isEnabled) return;

      addPages([<BookWorkspaceCard date={date} />]);
      openModal();
    }
  };

  const getIconName = () => {
    if (!workspaceReservation || workspaceReservation.deskId) {
      return 'desk';
    }

    if (workspaceReservation.areaId) {
      return 'area';
    }

    if (workspaceReservation.floorId) {
      return 'floor';
    }

    return 'office';
  };

  return (
    <SquareButton
      iconLeft={getIconName()}
      onClick={handleOnClick}
      iconRight="caretRight"
      disabled={(!isEnabled && !workspaceReservation) || workspacePending}
      squareButton="energizingYellow">
      {workspacePending ? <Loader className="size-6" /> : <CalendarWorkspaceTileBookingInfo date={date} />}
    </SquareButton>
  );
};
