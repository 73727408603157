import {CSSProperties} from 'react';
import {useTransition} from 'react-spring';
import {ModalBackgroundProps} from './types';

import {StyledModalBackgroundLayout} from './styles';

export const ModalBackground = ({
  clickable,
  hideOnSmallScreen = false,
  open,
  optStyle,
  ...props
}: ModalBackgroundProps) => {
  const transition = useTransition(open, {
    from: {opacity: 0, backdropFilter: 'blur(0px)', WebkitBackdropFilter: 'blur(0px'},
    enter: {opacity: 1, backdropFilter: 'blur(3px)', WebkitBackdropFilter: 'blur(3px'},
    leave: {opacity: 0, backdropFilter: 'blur(0px)', WebkitBackdropFilter: 'blur(0px'},
    config: {
      duration: 300,
    },
  });

  const ModalBackgroundStyle = {
    '--cursor': clickable ? 'pointer' : 'default',
    '--display-on-small-screen': hideOnSmallScreen ? 'none' : 'block',
  } as CSSProperties;

  return transition(
    (style, item) =>
      item && (
        <StyledModalBackgroundLayout
          {...props}
          $cursor={clickable}
          $hideOnSmallScreen={hideOnSmallScreen}
          style={{
            ...optStyle,
            ...ModalBackgroundStyle,
            ...style,
          }}
        />
      ),
  );
};
