import {useTranslation} from 'react-i18next';
import {Button} from '@molecules';
import {FlexCol, H1, H2, P} from '@quarks';
import {CardBody, CardBottom, CardTop} from '../styles';
import introImg from '@images/onboarding/intro.webp';
import {Workday, WorkdayStatus} from '@lib/store';
import {cn, resolveWorkdayStatusIcon} from '@utils';
import {Icon} from '@atoms';
import {OnboardingWrapper} from '@organisms';

interface OnboardingIntroCardProps {
  onNext: () => void;
}

export const OnboardingIntroCard = ({onNext}: OnboardingIntroCardProps) => {
  const {t} = useTranslation();

  const workdayItems = (['OfficeDay', 'WorkingRemotely', 'NotWorking'] as WorkdayStatus[]).map((status) => ({
    status,
    checked: status === 'OfficeDay',
    icon: resolveWorkdayStatusIcon(false, {status} as Workday),
    title: status === 'OfficeDay' ? t('workplace:AtTheOffice') : t(`workplace:${status}`),
  }));

  return (
    <OnboardingWrapper>
      <CardTop>
        <FlexCol gap={16}>
          <H1 id="onboarding-legend">{t('profile:PreferencesTitle')}</H1>
          <P>{t('profile:PreferencesDescription')}</P>
        </FlexCol>
      </CardTop>

      <CardBody
        role="radiogroup"
        tabIndex={0}
        className="min-h-40">
        <H2 className="my-3">{t('profile:YourQuota')}</H2>

        <div className="flex flex-col gap-2 mb-12">
          {workdayItems.map(({status, checked, icon, title}) => (
            <div
              key={status}
              className="flex items-center gap-4">
              <div
                className={cn([
                  'flex items-center justify-center rounded w-12 h-12',
                  {
                    'bg-energizing-yellow-500*': checked,
                    'bg-beige-500*': !checked,
                  },
                ])}>
                <Icon
                  icon={icon}
                  size="1.3rem"
                />
              </div>
              <P>{title}</P>
            </div>
          ))}
        </div>

        <img
          alt={t('profile:PreferencesDescription')}
          src={introImg}
        />
      </CardBody>

      <CardBottom>
        <Button
          button="primary"
          onClick={onNext}
          size="full">
          {t('Continue')}
        </Button>
      </CardBottom>
    </OnboardingWrapper>
  );
};
