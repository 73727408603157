import {useRef, useState, FocusEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '@molecules';
import {FlexCol, H2, P} from '@quarks';
import {Icon, Loader} from '@atoms';
import {CardBody, CardBottom, CardTop, StyledLoader} from '../styles';
import {COLOR_PALETTE} from '@constants';
import {OnboardingWrapper} from '../OnboardingWrapper';
import {OnboardingProgress} from '@organisms';
import {OnboardingStep} from '../types';

export interface OnboardingCardProps<T, S> {
  items: T[];
  title: string;
  description: string;
  initialValue: S;
  isLoading?: boolean;
  isSaving?: boolean;
  disableWhenNoSelection?: boolean;
  emptyText?: string;
  multiple?: boolean;
  currentStep?: OnboardingStep;
  renderItem: ({
    item,
    selectedItem,
    setSelectedItem,
    handleOnFocus,
  }: {
    item: T;
    selectedItem: S;
    setSelectedItem: (value: S) => void;
    handleOnFocus: (e: FocusEvent<HTMLLabelElement>) => void;
  }) => React.ReactNode;
  onBack?: () => void;
  onSelect: (selectedItem: S) => void;
}

export const OnboardingCard = <T, S>({
  items,
  title,
  initialValue,
  isLoading = false,
  isSaving = false,
  disableWhenNoSelection = false,
  description,
  emptyText,
  renderItem,
  currentStep,
  onBack,
  onSelect,
}: OnboardingCardProps<T, S>) => {
  const [selectedItem, setSelectedItem] = useState<S>(initialValue);
  const {t} = useTranslation();
  const parentRef = useRef<HTMLDivElement>(null);

  const saveSelection = () => {
    onSelect(selectedItem);
  };

  const handleOnFocus = (e: FocusEvent<HTMLLabelElement>) => {
    const parent = parentRef.current;

    if (!parent) return;

    const target = e.target;

    if (parent.scrollHeight > parent.clientHeight) {
      target.scrollIntoView({behavior: 'smooth', block: 'nearest'});
    }
  };

  return (
    <OnboardingWrapper>
      <CardTop>
        <FlexCol gap={16}>
          {onBack && (
            <div className="flex items-center gap-3">
              <Button
                button="text"
                onClick={onBack}
                size="full"
                className="mb-5 w-auto">
                <Icon
                  icon="arrowLeft"
                  size="1rem"
                  color={COLOR_PALETTE.collaborativeBlue[500]}
                />

                {t('Back')}
              </Button>
            </div>
          )}

          <OnboardingProgress
            currentStep={currentStep}
            showProgress
          />

          <div className="flex flex-col gap-1">
            <H2 id="onboarding-legend">{title}</H2>
            <P>{description}</P>
          </div>
        </FlexCol>
      </CardTop>

      <CardBody
        aria-labelledby="onboarding-legend"
        ref={parentRef}
        role="radiogroup"
        tabIndex={0}
        className="min-h-40">
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Loader />
          </div>
        ) : items.length > 0 ? (
          items.map((listItem) => renderItem({item: listItem, selectedItem, setSelectedItem, handleOnFocus}))
        ) : emptyText ? (
          <p className="pb-5">{emptyText}</p>
        ) : (
          <StyledLoader />
        )}
      </CardBody>

      <CardBottom>
        <Button
          button="primary"
          disabled={disableWhenNoSelection && !selectedItem}
          loading={isSaving}
          onClick={saveSelection}
          size="full">
          {t('Continue')}
        </Button>
      </CardBottom>
    </OnboardingWrapper>
  );
};
