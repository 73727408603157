import {OnboardingCard} from '../OnboardingCard';
import {Input} from '@molecules';
import {OnboardingItemCell, LabelText} from '../styles';
import {useTranslation} from 'react-i18next';
import {nanoid} from '@reduxjs/toolkit';
import {OnboardingStep} from '../types';
import {useParkingZoneOptions} from '@lib/utils/src/hooks/preferences';

interface OnboardingParkingZoneProps {
  parkingLotId?: string | null;
  initialValue?: string | null;
  onBack?: () => void;
  onSelectParkingZone: (selectedAreaId: string | null) => void;
}

export const OnboardingParkingZoneCard = ({
  parkingLotId = null,
  initialValue = null,
  onBack,
  onSelectParkingZone,
}: OnboardingParkingZoneProps) => {
  const {t} = useTranslation();
  const parkingZoneOptions = useParkingZoneOptions({parkingLotId});

  return (
    <OnboardingCard
      items={parkingZoneOptions}
      title={t('parking:ParkingZonePreference')}
      description={t('parking:ParkingPreferenceDescription')}
      currentStep={OnboardingStep.ParkingZone}
      initialValue={initialValue}
      renderItem={({item: parkingZone, selectedItem, setSelectedItem, handleOnFocus}) => (
        <OnboardingItemCell
          key={parkingZone.value ?? nanoid()}
          onFocus={handleOnFocus}>
          <Input
            type="radio"
            name="parkingZone"
            checked={selectedItem === parkingZone.value}
            onChange={() => setSelectedItem(parkingZone.value)}
          />
          <LabelText>{parkingZone.label}</LabelText>
        </OnboardingItemCell>
      )}
      onBack={() => onBack?.()}
      onSelect={onSelectParkingZone}
    />
  );
};
