import {OnboardingCard} from '../OnboardingCard';
import {Input} from '@molecules';
import {OnboardingItemCell, LabelText} from '../styles';
import {useTranslation} from 'react-i18next';
import {nanoid} from '@reduxjs/toolkit';
import {OnboardingStep} from '../types';
import {useParkingLotOptions} from '@lib/utils/src/hooks/preferences';
import {
  withAsyncThunkErrorHandling,
  loadParkingLotsByBuilding,
  useAppDispatch,
  getDefaultBuildingId,
  useAppSelector,
  getIsLoadingParkingLots,
} from '@lib/store';
import {useEffect} from 'react';

interface OnboardingParkingLotProps {
  initialValue?: string | null;
  onBack?: () => void;
  onSelectParkingLot: (selectedParkingLot: string | null) => void;
}

export const OnboardingParkingLotCard = ({
  initialValue = null,
  onBack,
  onSelectParkingLot,
}: OnboardingParkingLotProps) => {
  const {t} = useTranslation();
  const isLoadingParkingLots = useAppSelector(getIsLoadingParkingLots);

  const parkingLotOptions = useParkingLotOptions();

  return (
    <OnboardingCard
      items={parkingLotOptions}
      title={t('parking:ParkingLotPreference')}
      description={t('parking:ParkingPreferenceDescription')}
      currentStep={OnboardingStep.ParkingLot}
      initialValue={initialValue}
      isLoading={isLoadingParkingLots}
      renderItem={({item: parkingLot, selectedItem, setSelectedItem, handleOnFocus}) => (
        <OnboardingItemCell
          key={parkingLot.value ?? nanoid()}
          onFocus={handleOnFocus}>
          <Input
            type="radio"
            name="parkingLot"
            checked={selectedItem === parkingLot.value}
            onChange={() => setSelectedItem(parkingLot.value)}
          />
          <LabelText>{parkingLot.label}</LabelText>
        </OnboardingItemCell>
      )}
      onBack={() => onBack?.()}
      onSelect={onSelectParkingLot}
    />
  );
};
