import {Children, cloneElement, isValidElement, useEffect, useRef, useState} from 'react';
import {OptionProps, SelectProps, SelectValue} from './types';

import {useClickOutside, useScrollOutside} from '@hooks';
import {pxToRem, scrollParentForChild} from '@utils';

import {ComboBox, ListBox, SelectIcon, Wrapper} from './styles';

export const Select = <TValue extends SelectValue>({
  children,
  className,
  setValue,
  style,
  value,
}: SelectProps<TValue>) => {
  const [open, setOpen] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const listBoxRef = useRef<HTMLDivElement>(null);
  const clickOutsideRef = useClickOutside(() => setOpen(false));
  const scrollOutsideRef = useScrollOutside(() => setOpen(false));

  const [childrenCount, setChildrenCount] = useState<number>();
  const [childrenLabels, setChildrenLabels] = useState<string[] | null | undefined>(null);
  const [childrenValues, setChildrenValues] = useState<TValue[] | null | undefined>(null);

  const [listBoxIsInView, setListBoxIsInView] = useState<boolean>(true);
  const [listBoxHeight, setListBoxHeight] = useState<number>(0);

  useEffect(() => {
    const count = Children.count(children);

    const values = Children.map(children, (element) => {
      if (isValidElement(element)) {
        const {value: returnValue} = element.props;
        return returnValue;
      }
    });

    const labels = Children.map(children, (element) => {
      if (isValidElement(element)) {
        const {children: label} = element.props;
        return label;
      }
    });

    const currentlySelectedIndex = Children.map(children, (element, i) => {
      if (isValidElement(element)) {
        const {value: returnValue} = element.props;
        if (value === returnValue) {
          return i;
        }
      }
    });

    const inferredSelectedIndex = currentlySelectedIndex ? currentlySelectedIndex[0] : 0;
    setSelectedIndex(inferredSelectedIndex);

    setChildrenCount(count);
    setChildrenLabels(labels);
    setChildrenValues(values);
  }, [value, children]);

  const handleComboBoxClick = () => {
    setOpen(!open);
  };

  /**
   * * CONTROLS
   * When menu is closed:
   * SpaceBar, ArrowUp, Arrowdown: Opens up the menu
   *
   * When menu is open:
   * ArrowDown, ArrowUp: Scroll, 1 option per click
   * PageDown, PageUp: Scroll, 10 options per click
   * Esc: Closes menu
   * Enter, Tab: Selects the current selection and closes the menu
   * TODO Add controls for PageUp & PageDown
   */
  const handleComboBoxKeyDown = (e: any) => {
    // We still want tabbing to work like normal,
    // but pressing arrow buttons normally scroll the page and we want to avoid that.
    if (e.key !== 'Tab' && e.key !== 'Shift') {
      if (!open) {
        e.preventDefault();
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.code === 'Space') setOpen(true);
      }
    }

    if (open && childrenCount) {
      e.preventDefault();
      if (e.key === 'ArrowUp') {
        if (activeIndex === 0) {
          setActiveIndex(childrenCount - 1);
        } else {
          setActiveIndex((ai) => ai - 1);
        }
      }
      if (e.key === 'PageUp') {
        if (activeIndex === 0) {
          setActiveIndex(childrenCount - 1);
        } else if (activeIndex <= 10) {
          setActiveIndex(0);
        } else {
          setActiveIndex((ai) => ai - 10);
        }
      }
      if (e.key === 'ArrowDown') {
        if (activeIndex === childrenCount - 1) {
          setActiveIndex(0);
        } else {
          setActiveIndex((ai) => ai + 1);
        }
      }
      if (e.key === 'PageDown') {
        if (activeIndex === childrenCount - 1) {
          setActiveIndex(0);
        } else if (childrenCount - 1 - activeIndex <= 10) {
          setActiveIndex(childrenCount - 1);
        } else {
          setActiveIndex((ai) => ai + 10);
        }
      }
      if (e.key === 'Enter' || e.key === 'Tab') {
        if (childrenValues) {
          setValue(childrenValues[activeIndex]);
          setOpen(false);
        }
      }
      if (e.key === 'Escape') setOpen(false);
    }
  };

  // We can't put two refs on one element so we have to do it programatically
  useEffect(() => {
    clickOutsideRef.current = wrapperRef.current;
    scrollOutsideRef.current = wrapperRef.current;
  }, [clickOutsideRef, scrollOutsideRef]);

  // We want to keep the active element in view
  // when user tries to access it with keyboard
  // i.e. this scrolls our listbox
  useEffect(() => {
    if (listBoxRef.current && open) {
      const activeOption = listBoxRef.current.children[activeIndex] as HTMLElement;
      scrollParentForChild(activeOption, listBoxRef.current);
    }
  }, [activeIndex, open]);

  // The listBox open automatically under the comboBox, however if there's not enough space on the page, or the user is not scroll far down enough for the listBox to show we want it to open up above the comboBox instead
  useEffect(() => {
    if (listBoxRef.current && clickOutsideRef.current && open) {
      const {bottom: parentBottom} = clickOutsideRef.current.getBoundingClientRect();
      const boxHeight = listBoxRef.current.getBoundingClientRect().height + 4;
      const viewPort = window.visualViewport;
      if (viewPort) {
        const viewPortHeight = viewPort.height;
        setListBoxHeight(boxHeight);

        const fits = viewPortHeight - parentBottom > boxHeight;

        if (fits) setListBoxIsInView(true);
        if (!fits) setListBoxIsInView(false);
      }
    }
  }, [open, clickOutsideRef, listBoxIsInView]);

  const childrenWithProps = Children.map(children, (child, index) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        activeIndex,
        index,
        selectedValue: value,
        setActiveIndex,
        setOpen,
        setValue,
      } as OptionProps<TValue>);
    }
    return child;
  });

  return (
    <Wrapper
      className={className}
      ref={wrapperRef}
      style={style}>
      <ComboBox
        aria-controls="listbox"
        aria-expanded={open}
        onClick={handleComboBoxClick}
        onKeyDown={handleComboBoxKeyDown}
        $open={open}
        tabIndex={0}>
        <span>{childrenLabels && value ? childrenLabels[selectedIndex] : value}</span>
        <SelectIcon
          $open={open}
          icon="caretDown"
          focusable={false}
          size={pxToRem(24)}
        />
      </ComboBox>
      {open && (
        <ListBox
          className="w-full"
          $listBoxIsInView={listBoxIsInView}
          $listBoxHeight={listBoxHeight}
          ref={listBoxRef}
          role="listbox">
          {childrenWithProps}
        </ListBox>
      )}
    </Wrapper>
  );
};
