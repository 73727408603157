import styled from 'styled-components';

export const OnboardingLayout = styled.div`
  align-items: center;
  display: grid;
  grid-area: fullbody-start / content-start / body-end / content-end;
  grid-template-columns: [card-start] 1fr [card-end];
  grid-template-rows: [card-start] 1fr [card-end];
  position: relative;
`;
