import {Icon} from '@atoms';
import {Button} from '@molecules';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useTransition, animated} from '@react-spring/web';

type Props = {
  handleDismiss: () => void;
  title: string;
  content: string;
  link?: string;
};

export const EmployeeCommunicationAnnouncement = ({handleDismiss, title, content, link}: Props) => {
  const {t} = useTranslation();
  const [transitions, api] = useTransition(null, () => ({
    from: {opacity: 0, transform: 'scale(0.9)'},
    enter: {opacity: 1, transform: 'scale(1)'},
    leave: {opacity: 0, transform: 'scale(0.9)'},
  }));
  useEffect(() => {
    api.start();
  }, []);

  return transitions((style, item) => (
    <animated.div
      style={style}
      className="bg-safe-mauve-500* rounded-lg+ p-6 grid gap-4">
      <div className="bg-white/15 size-10 rounded-lg flex items-center justify-center">
        <Icon
          className="size-6"
          color="white"
          size="default"
          icon="megaPhone"
        />
      </div>

      <div className="flex flex-col text-white gap-2.5">
        <span>
          <h3 className="text-white leading-none">{title}</h3>
          <p>{content}</p>
        </span>

        <div className="flex gap-2.5">
          {link && (
            <Button
              as="a"
              /** @ts-expect-error */
              href={link}
              target="_blank"
              size="small"
              button={'primary light'}
              iconRight="openInBrowser">
              {t('moreInfo')}
            </Button>
          )}
          <Button
            size="small"
            button={'tertiary light'}
            onClick={handleDismiss}>
            {t('dismiss')}
          </Button>
        </div>
      </div>
    </animated.div>
  ));
};
