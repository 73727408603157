import {PageHeaderProps} from './types';
import {PageHeaderWrapper} from './styles';

import {Div, FlexRow, H1, Span} from '@quarks';
import {Button, IconButton} from '@molecules';
import {useTranslation} from 'react-i18next';
import {Icon} from '@atoms';

export const PageHeader = ({
  buttonIcons,
  className,
  style,
  title,
  onBuildingSelect,
  children,
  ...props
}: PageHeaderProps) => {
  const {t} = useTranslation();
  return (
    <PageHeaderWrapper
      className={className}
      style={style}
      {...props}>
      {children ? (
        children
      ) : (
        <>
          <FlexRow
            gap={8}
            alignItems="center">
            <H1
              sm={{
                gridArea: 'title',
              }}>
              {title}
            </H1>

            {onBuildingSelect ? (
              <Icon
                style={{cursor: 'pointer'}}
                weight="regular"
                size="24px"
                icon="caretDown"
                aria-label={t('Building')}
                onClick={onBuildingSelect}
              />
            ) : null}
          </FlexRow>
          {buttonIcons?.length && (
            <FlexRow
              gridArea="button"
              justifyContent="flex-end"
              gap={8}>
              {buttonIcons.map(({icon, label, onClick, showButton}) => {
                return (
                  <Span key={icon + label}>
                    {showButton === 'all' || showButton === 'desktop' ? (
                      <Div sm={{display: 'none'}}>
                        <Button
                          button="tertiary"
                          iconRight={icon}
                          onClick={onClick}>
                          {label}
                        </Button>
                      </Div>
                    ) : null}
                    {showButton === 'all' || showButton === 'mobile' ? (
                      <Div
                        display="none"
                        sm={{display: 'flex', gridArea: 'button', justifySelf: 'flex-end'}}>
                        <IconButton
                          aria-label={label}
                          icon={icon}
                          iconButton="tertiary"
                          onClick={() => onClick()}
                        />
                      </Div>
                    ) : null}
                  </Span>
                );
              })}
            </FlexRow>
          )}
        </>
      )}
    </PageHeaderWrapper>
  );
};
