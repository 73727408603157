import {createPortal} from 'react-dom';
import {config, useTransition} from '@react-spring/web';

import {HaNModalProps} from './types';
import FocusTrap from 'focus-trap-react';
import {useBreakPoint} from '@hooks';
import {HaNFacilityModal, HaNRoomModal, HaNWorkspaceModal, ModalBackground} from '@organisms';
import {ModalWrapper} from './styles';
import {useEffect, useState} from 'react';

const modalRoot = document.getElementById('modal-portal')!;

/**
 * Modal specificly designed for Here and Now.
 * Do not use elsewhere.
 */

export const HaNModalContainer = ({
  show,
  onClose,
  entity,
  endDateTime,
  startDateTime,
  setWarnBeforeOpeningNewRoom,
  onBookNowCallback,
  duration,
}: HaNModalProps) => {
  const breakPoint = useBreakPoint();
  const from =
    breakPoint === 'small'
      ? {transform: 'translate3d(0, 100px, 0)', opacity: 0}
      : {transform: 'translate3d(-100px, 0, 0)', opacity: 0};
  const enter = {transform: 'translate3d(0px, 0px, 0)', opacity: 1};
  const transition = useTransition(show && entity, {
    from: from,
    enter: enter,
    leave: from,
    config: {...config.stiff, clamp: true},
    keys: (item) => `${item}-key`,
  });

  const [focusTrapActive, setFocusTrapActive] = useState(true);

  useEffect(
    function listenForEsc() {
      const listener = (e: KeyboardEvent) => {
        if (show && e.key === 'Escape') onClose?.();
      };

      window.addEventListener('keydown', listener);
      return () => window.removeEventListener('keydown', listener);
    },
    [show, onClose],
  );

  // manually activate the focus trap when the content of the modal gets updated
  // Needed incase the focus is disabled due to outside clicks.
  useEffect(() => {
    setFocusTrapActive(true);
  }, [entity?.id]);

  return createPortal(
    <>
      {breakPoint === 'small' && (
        <ModalBackground
          clickable={false}
          hideOnSmallScreen={false}
          open={show}
        />
      )}
      {transition(
        (style, item) =>
          item && (
            <FocusTrap
              active={focusTrapActive}
              focusTrapOptions={{
                allowOutsideClick: true,
                clickOutsideDeactivates: true,
                escapeDeactivates: false,
                onDeactivate: () => setFocusTrapActive(false),
              }}>
              <ModalWrapper style={{...style, pointerEvents: 'all'}}>
                {item.type === 'meetingRoom' ? (
                  <HaNRoomModal
                    key={item.id}
                    onClose={onClose}
                    room={item}
                    endDateTime={endDateTime}
                    startDateTime={startDateTime}
                    onBookNowCallback={onBookNowCallback}
                    duration={duration}
                    setWarnBeforeOpeningNewRoom={setWarnBeforeOpeningNewRoom}
                  />
                ) : item.type === 'area' ? (
                  <HaNWorkspaceModal
                    onClose={onClose}
                    workspace={item}
                  />
                ) : item.type === 'facility' ? (
                  <HaNFacilityModal
                    onClose={onClose}
                    facility={item}
                  />
                ) : null}
              </ModalWrapper>
            </FocusTrap>
          ),
      )}
    </>,
    modalRoot,
  );
};
