import {OnboardingWelcomeCardProps} from './types';

import {getSettings, getUserFirstName} from '@lib/store';

import {useTranslation} from 'react-i18next';

import {useAppSelector} from '@hooks';

import {Button} from '@molecules';
import {MoreInformationCell, OnboardingWrapper, QuestionsCell, QuotaCard} from '@organisms';
import {FlexCol, H1, H2, P} from '@quarks';
import {CardBottom, CardTop, QuotaWrapper, WelcomeCard} from '../styles';

export const OnboardingWelcomeCard = ({handleContinue}: OnboardingWelcomeCardProps) => {
  const userFirstName = useAppSelector(getUserFirstName);
  const {officeInstructions} = useAppSelector(getSettings);
  const {t} = useTranslation();

  return (
    <OnboardingWrapper>
      <CardTop>
        <H1>{t('profile:HelloMessage', {name: userFirstName})}</H1>
      </CardTop>
      <WelcomeCard>
        <P>{officeInstructions}</P>
        <QuotaWrapper>
          <H2>{t('profile:YourQuota')}</H2>
          <QuotaCard />
        </QuotaWrapper>
        <FlexCol>
          <QuestionsCell />
          <hr />
          <MoreInformationCell />
        </FlexCol>
      </WelcomeCard>
      <CardBottom>
        <Button
          button="primary"
          onClick={handleContinue}
          size="full">
          {t('Continue')}
        </Button>
      </CardBottom>
    </OnboardingWrapper>
  );
};
