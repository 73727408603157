import {useModal} from '@hooks';
import {
  createWorkday,
  getPreferences,
  getWorkdayByDate,
  useAppDispatch,
  useAppSelector,
  withAsyncThunkErrorHandling,
  WorkdayStatus,
} from '@lib/store';
import {IconButton} from '@molecules';
import {BookingLocationStatusCard} from '@organisms';
import {cn, IconStatusMapping} from '@utils';
import React, {Dispatch, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {ErrorObject} from '../types';

type Props = {
  disabled: boolean;
  date: string;

  processingFor: WorkdayStatus | null;
  setProcessingFor: Dispatch<SetStateAction<WorkdayStatus | null>>;

  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;

  errorObject: ErrorObject;
  setErrorObject: Dispatch<SetStateAction<ErrorObject>>;
};

export const QuickWorkingRemotelyButton = ({
  disabled,
  date,
  loading,
  setLoading,
  errorObject,
  setErrorObject,
  processingFor,
  setProcessingFor,
}: Props) => {
  const forWorkdayStatus: WorkdayStatus = 'WorkingRemotely' as const;

  const {t} = useTranslation();
  const {addPages, openModal} = useModal();
  const dispatch = useAppDispatch();
  const {workspaceLocation} = useAppSelector(getPreferences);
  const workdayByDate = useAppSelector((state) => getWorkdayByDate(state, date));

  const createWorkingRemotelyWorkday = async () => {
    const {floorId, areaId} = workspaceLocation || {};
    if (!floorId) return;
    setLoading(true);
    setErrorObject((p) => ({
      ...p,
      workday: null,
    }));
    setProcessingFor(forWorkdayStatus);

    const {success} = await dispatch(
      withAsyncThunkErrorHandling(() =>
        createWorkday({
          status: forWorkdayStatus,
          endDate: date,
          startDate: date,
        }),
      ),
    );

    if (!success) {
      setErrorObject((p) => ({
        ...p,
        workday: t('workplace:CouldntFindASpot'),
      }));
    }

    setProcessingFor(null);
    setLoading(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (workdayByDate) {
      addPages([<BookingLocationStatusCard date={date} />]);
      openModal();
    } else {
      createWorkingRemotelyWorkday();
    }
  };

  return (
    <>
      <IconButton
        className={cn('shrink-0', {
          'bg-energizing-yellow-500*': processingFor === forWorkdayStatus || workdayByDate?.status === forWorkdayStatus,
        })}
        disabled={disabled}
        iconButton="tertiary"
        icon={IconStatusMapping[forWorkdayStatus]}
        onClick={handleClick}
      />
      {!loading && workdayByDate && (
        <p className="truncate leading-none text-mapiq-black-500">{t('workplace:WorkingRemotely')}</p>
      )}
    </>
  );
};
