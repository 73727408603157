import styled from 'styled-components';
import {SidePanelLayout} from '@templates';

export const StyledSidePanelLayout = styled(SidePanelLayout)`
  background: ${({theme}) => theme.background.white};
  border: none;
  overflow-y: auto;
  box-shadow: ${({theme}) => theme.boxShadow.secondary};
  padding: 20px;
  z-index: 2;
  pointer-events: all;
`;
