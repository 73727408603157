import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {animated, useTransition, config} from 'react-spring';
import {useSetInitialOnboardingState} from '@lib/utils/src/hooks/onboarding';
import {
  useAppSelector,
  getAllBuildings,
  getDefaultBuildingId,
  getIsNew,
  getIsOnboarded,
  getIsLoadingCalendar,
  getIsExperienceImprovementsEnabled,
  getOnboardingState,
  getIsParkingFeatureVisible,
} from '@lib/store';
import {Loader} from '@atoms';
import {Canvas, OnboardingLayout} from '@templates';
import {
  OnboardingWelcomeCard,
  OnboardingIntroCard,
  OnboardingBuildingsCard,
  OnboardingBuildingsCardNew,
  OnboardingFloorCard,
  OnboardingAreaCard,
  OnboardingDeskAmenitiesCard,
  OnboardingOverviewCard,
  OnboardingParkingZoneCard,
  OnboardingParkingLotCard,
} from '@organisms';
import {OnboardingStep} from '../../organisms/onboarding/types';
import {useOnboarding} from '@hooks';
import {usePreferencesOverview} from '@lib/utils/src/hooks/preferences';

/**
 * * INSTRUCTIONS
 * * isNew = true
 * * buildings.length > 1
 * Show welcome card, pass function that flips page to building selection, once building is selected and continue is pressed save the selected building, set isNew to false and then end the flow.
 *
 * * isNew = true
 * * buildings.length === 1
 * Show welcome card, but pass a function that saves the single building and sets isNew to false when continue is pressed and then end the onboarding flow.
 *
 * * isNew = true
 * * buildings.length === 0
 * Show welcome card, pass a function that saves an empty string instead of a building id when continue is pressed, set isNew to false and then end the onboarding flow.
 *
 * * isNew = false
 * * buildings.length > 1
 * * defaultBuilding = ''
 * Show the building selection screen, save selected building and then end the flow.
 *
 * * isNew = false
 * * buildings.length === 1
 * * defaultBuilding = ''
 * Don't go through onboarding flow, but save the sole building as default.
 *
 * * isNew = false
 * * defaultBuilding = buildingId
 * Don't go through onboarding flow.
 */

export const Onboarding = () => {
  const isLoading = useAppSelector(getIsLoadingCalendar);
  const buildings = useAppSelector(getAllBuildings);
  const isNew = useAppSelector(getIsNew);
  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisible);
  const isOnboarded = useAppSelector((state) => getIsOnboarded(state, isParkingFeatureVisible));
  const defaultBuilding = !!useAppSelector(getDefaultBuildingId);
  const defaultBuildingId = useAppSelector(getDefaultBuildingId);
  const onboardingState = useAppSelector(getOnboardingState);
  const isExperienceImprovementsEnabled = useAppSelector(getIsExperienceImprovementsEnabled);

  const {floorId, areaId, deskAmenities, parkingLotId, parkingZoneId, isDeskRequired} = onboardingState;

  const {isSaving, savePreferences} = usePreferencesOverview({
    buildingId: defaultBuildingId,
    floorId,
    areaId,
    deskAmenities,
    parkingLotId,
    parkingZoneId,
    isDeskRequired,
  });

  const {
    step,
    setStep,
    saveBuilding,
    setFloor,
    setArea,
    setDeskAmenities,
    setParkingLot,
    setParkingZone,
    setIsDeskRequired,
  } = useOnboarding();

  useSetInitialOnboardingState();

  const [animationDirection, setAnimationDirection] = useState<'forward' | 'backward'>('forward');

  const transitions = useTransition(step, {
    from: {
      opacity: 0,
      transform: animationDirection === 'forward' ? 'translate3d(100%, 0, 0)' : 'translate3d(-100%, 0, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    leave: {
      opacity: 0,
      transform: animationDirection === 'forward' ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)',
    },
    config: {clamp: true, ...config.stiff},
    onRest: () => {
      if (animationDirection === 'backward') {
        setAnimationDirection('forward');
      }
    },
  });

  useEffect(() => {
    if (!isNew && !defaultBuilding && buildings.length > 1) {
      setStep(OnboardingStep.Building);
    }
  }, [buildings, defaultBuilding, isNew, setStep]);

  const handleContinue = () => {
    if (isExperienceImprovementsEnabled) {
      setStep(OnboardingStep.Building);
      return;
    }

    if (buildings.length === 1) {
      saveBuilding(buildings[0].id);
    } else if (buildings.length === 0) {
      saveBuilding(null);
    }

    setStep(OnboardingStep.Building);
  };

  const handleBack = (newStep: OnboardingStep) => {
    setAnimationDirection('backward');
    setStep(newStep);
  };

  if (!isOnboarded || (!defaultBuilding && buildings.length > 1)) {
    if (isLoading) {
      return (
        <Canvas centerContent>
          <Loader />
        </Canvas>
      );
    }

    return (
      <>
        <Canvas />

        <OnboardingLayout>
          {transitions((style, currentStep) => (
            <animated.div
              style={style}
              className="absolute w-full h-full flex flex-col justify-center">
              {currentStep === OnboardingStep.Welcome && <OnboardingWelcomeCard handleContinue={handleContinue} />}

              {isExperienceImprovementsEnabled && currentStep === OnboardingStep.Intro && (
                <OnboardingIntroCard onNext={() => setStep(OnboardingStep.Building)} />
              )}

              {currentStep === OnboardingStep.Building &&
                (isExperienceImprovementsEnabled ? (
                  <OnboardingBuildingsCardNew
                    buildings={buildings}
                    onSelectBuilding={saveBuilding}
                  />
                ) : (
                  <OnboardingBuildingsCard
                    page={step}
                    buildings={buildings}
                    onSelectBuilding={saveBuilding}
                  />
                ))}

              {isExperienceImprovementsEnabled && (
                <>
                  {currentStep === OnboardingStep.Floor && (
                    <OnboardingFloorCard
                      onBack={() => handleBack(OnboardingStep.Building)}
                      onSelectFloor={setFloor}
                      initialValue={floorId}
                    />
                  )}

                  {currentStep === OnboardingStep.Area && (
                    <OnboardingAreaCard
                      floorId={floorId}
                      onBack={() => handleBack(OnboardingStep.Floor)}
                      onSelectArea={setArea}
                      initialValue={areaId}
                    />
                  )}

                  {currentStep === OnboardingStep.DeskAmenities && (
                    <OnboardingDeskAmenitiesCard
                      areaId={areaId}
                      initialValue={deskAmenities}
                      onBack={() => handleBack(OnboardingStep.Area)}
                      onSelectDeskAmenities={setDeskAmenities}
                    />
                  )}

                  {currentStep === OnboardingStep.ParkingLot && (
                    <OnboardingParkingLotCard
                      initialValue={parkingLotId}
                      onBack={() => {
                        if (!floorId) {
                          handleBack(OnboardingStep.Floor);
                          return;
                        }

                        if (!areaId) {
                          handleBack(OnboardingStep.Area);
                          return;
                        }

                        handleBack(OnboardingStep.DeskAmenities);
                      }}
                      onSelectParkingLot={setParkingLot}
                    />
                  )}

                  {currentStep === OnboardingStep.ParkingZone && (
                    <OnboardingParkingZoneCard
                      parkingLotId={parkingLotId}
                      initialValue={parkingZoneId}
                      onBack={() => handleBack(OnboardingStep.ParkingLot)}
                      onSelectParkingZone={setParkingZone}
                    />
                  )}

                  {currentStep === OnboardingStep.Overview && (
                    <OnboardingOverviewCard
                      buildingId={defaultBuildingId}
                      floorId={floorId}
                      areaId={areaId}
                      deskAmenities={deskAmenities}
                      isDeskRequired={isDeskRequired}
                      parkingLotId={parkingLotId}
                      parkingZoneId={parkingZoneId}
                      isSaving={isSaving}
                      onSetIsDeskRequired={setIsDeskRequired}
                      onSetStep={(newStep) => {
                        setAnimationDirection('backward');
                        setStep(newStep);
                      }}
                      onBack={() =>
                        handleBack(isParkingFeatureVisible ? OnboardingStep.ParkingLot : OnboardingStep.DeskAmenities)
                      }
                      onSave={savePreferences}
                    />
                  )}
                </>
              )}
            </animated.div>
          ))}
        </OnboardingLayout>
      </>
    );
  }

  return <Outlet />;
};
