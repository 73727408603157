import {useEffect, useMemo, useState} from 'react';

import {
  getAppLanguage,
  getCalendar,
  getConnectedUsersWithAndWithoutBookings,
  getIsExperienceImprovementsEnabled,
  getIsLoadingCalendar,
  useOverridableFeatureFlags,
} from '@lib/store';

import {useAppSelector, useModal} from '@hooks';
import {Div, FlexRow, H1, H4} from '@quarks';
import {Loader} from '@atoms';
import {
  CalendarDayOverviewCard,
  CalendarHorizontalWeekOverview,
  CalendarOverviewConnectionFilter,
  CalendarWeek,
  CalendarWeekDay,
  CalendarWeekDay_ExperienceImprovements,
  NewFilterCard,
} from '@organisms';
import {CalendarDayOverview, CalendarLayout, CalendarWeekOverview, Canvas, Heading} from '@templates';
import {Navigate, useParams} from 'react-router-dom';
import {isValid, parseISO, startOfToday} from 'date-fns';
import {constructCalendarOverviewConnectionFilter, formatDate, isBeforeToday, useFilterData} from '@lib/utils';
import {CalendarHorizontalWeekOverviewWrapper, CalendarPageHeader, OverviewWrapper} from './styles';
import {useTranslation} from 'react-i18next';
import {trackPageComponentedRendered} from '@lib/infrastructure';
import {IconButton} from '@molecules';

export const Calendar = () => {
  const enabledFeatures = useOverridableFeatureFlags();
  const [showCalendar, setShowCalendar] = useState(enabledFeatures.ExperienceImprovements || false);
  const isLoading = useAppSelector(getIsLoadingCalendar);
  const calendarWeeks = useAppSelector(getCalendar);
  const {t} = useTranslation();
  const {date} = useParams();
  const today = startOfToday();
  const dateAsString = date ? date : today.toISOString();
  const appLanguage = useAppSelector(getAppLanguage);
  const {addPages, openModal} = useModal();

  const isExperienceImprovementsEnabled = useAppSelector(getIsExperienceImprovementsEnabled);

  const toggleDayAndWeekView = () => {
    window.scrollTo(0, 0);
    setShowCalendar((prev) => !prev);
  };

  const {connectionsWithWorkdays, connectionsWithoutWorkdays} = useAppSelector(getConnectedUsersWithAndWithoutBookings);

  const filters = useMemo(
    () => constructCalendarOverviewConnectionFilter(connectionsWithWorkdays, connectionsWithoutWorkdays, t),
    [connectionsWithWorkdays, connectionsWithoutWorkdays, t],
  );
  const {filteredData} = useFilterData(
    'CalendarOverview_Connections',
    'cacheKey',
    [...connectionsWithWorkdays, ...connectionsWithoutWorkdays],
    filters,
    'or',
    isLoading,
  );
  const selectedCalendarConnectionFilterIds = filteredData.map((c) => c.id);

  useEffect(() => {
    trackPageComponentedRendered('calendar', !isLoading);
  }, [isLoading]);
  /**
   * Our router needs some help parsing the routes
   * so if we don't get the root URL or a valid
   * date we'll send them to our 404 page
   */
  if (date !== undefined && !isValid(new Date(dateAsString!))) {
    return <Navigate to="/fourohfour" />;
  }

  if (!date || isBeforeToday(parseISO(dateAsString), today)) {
    return <Navigate to={`/calendar/${formatDate(today, 'yyyy-MM-dd', 'en')}`} />;
  }
  /**
   * Temporary solution until we implement
   * separate loading states for each part of the
   * calendar
   * */
  if (isLoading) {
    return (
      <>
        <Heading>
          <CalendarPageHeader
            title={
              showCalendar ? t('translation:Calendar') : formatDate(parseISO(dateAsString), 'EEEE d MMMM', appLanguage)
            }
          />
        </Heading>
        <Canvas centerContent>
          <Loader />
        </Canvas>
      </>
    );
  }

  function openFilterModal() {
    addPages([
      <NewFilterCard
        filterSlice={'CalendarOverview_Connections'}
        searchBarPlaceholderText={t('connection:SearchConnectionPlaceholder')}
        searchByProperties={['email', 'name']}
      />,
    ]);
    openModal();
  }

  return (
    <>
      <Heading>
        {showCalendar ? (
          isExperienceImprovementsEnabled ? (
            <CalendarPageHeader className="gap-4 justify-between items-center flex">
              <H1>{t('translation:Calendar')}</H1>
              <IconButton
                icon="filter"
                iconButton="tertiary"
                onClick={openFilterModal}
              />
            </CalendarPageHeader>
          ) : (
            <CalendarPageHeader title={t('translation:Calendar')} />
          )
        ) : isExperienceImprovementsEnabled ? (
          <CalendarPageHeader className="gap-4 justify-center items-center grid grid-cols-[1fr_4fr_1fr]">
            <IconButton
              className=" aligself-start justify-self-start bg-transparent shadow-none"
              icon={'caretLeft'}
              iconButton="tertiary"
              onClick={() => setShowCalendar(true)}
            />
            <H4 className="text-center">{formatDate(parseISO(dateAsString), 'EEEE d MMMM', appLanguage)}</H4>
          </CalendarPageHeader>
        ) : (
          <CalendarPageHeader
            buttonIcons={[
              {
                icon: !showCalendar ? 'weekView' : 'dayView',
                label: 'Week view',
                onClick: toggleDayAndWeekView,
                showButton: 'mobile',
              },
            ]}
            title={formatDate(parseISO(dateAsString), 'EEEE d MMMM', appLanguage)}
          />
        )}

        {isExperienceImprovementsEnabled ? (
          <FlexRow
            alignItems="center"
            justifyContent="center"
            sm={{display: 'none'}}>
            <H4 data-testid="pages-Calendar-Calendar_calendarHeader">ksk</H4>
          </FlexRow>
        ) : (
          <FlexRow
            alignItems="center"
            justifyContent="center"
            sm={{display: 'none'}}>
            <H1 data-testid="pages-Calendar-Calendar_calendarHeader">
              {formatDate(parseISO(dateAsString), 'EEEE d MMMM', appLanguage)}
            </H1>
          </FlexRow>
        )}
      </Heading>

      <CalendarLayout>
        <CalendarDayOverview $showCalendar={showCalendar}>
          <CalendarHorizontalWeekOverviewWrapper>
            <CalendarHorizontalWeekOverview calendarWeeks={calendarWeeks} />
          </CalendarHorizontalWeekOverviewWrapper>
          <OverviewWrapper>
            <FlexRow
              alignItems="center"
              justifyContent="center"
              sm={{display: 'none'}}>
              <H1 data-testid="pages-Calendar-Calendar_calendarHeader">
                {formatDate(parseISO(dateAsString), 'EEEE d MMMM', appLanguage)}
              </H1>
            </FlexRow>
            <CalendarDayOverviewCard date={dateAsString} />
          </OverviewWrapper>
        </CalendarDayOverview>

        <CalendarWeekOverview $showCalendar={showCalendar}>
          <Div sm={{display: 'none'}}>
            <CalendarOverviewConnectionFilter />
          </Div>

          {calendarWeeks.map((data) => (
            <CalendarWeek
              key={`calendarCard-${data.weekNumber}`}
              weekNumber={data.weekNumber}
              weekType={data.weekType}>
              {data.data.map((day) =>
                enabledFeatures.ExperienceImprovements ? (
                  <CalendarWeekDay_ExperienceImprovements
                    date={day.date}
                    key={day.date}
                    isToday={day.isToday}
                    toggleDayAndWeekView={toggleDayAndWeekView}
                    showConnectionsWithIds={selectedCalendarConnectionFilterIds}
                  />
                ) : (
                  <CalendarWeekDay
                    date={day.date}
                    key={day.date}
                    isToday={day.isToday}
                    toggleDayAndWeekView={toggleDayAndWeekView}
                    showConnectionsWithIds={selectedCalendarConnectionFilterIds}
                  />
                ),
              )}
            </CalendarWeek>
          ))}
        </CalendarWeekOverview>
      </CalendarLayout>
    </>
  );
};
