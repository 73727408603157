import {PropsWithChildren} from 'react';
import {StyledCard} from '../styles';

export const OnboardingWrapper = ({children}: PropsWithChildren) => {
  return (
    <StyledCard
      height="100%"
      maxHeight="80vh"
      hasBorder>
      {children}
    </StyledCard>
  );
};
