import {Workday, WorkdayStatus} from '@lib/store';
import {IconNames} from 'mapiq-atoms';

export const IconStatusMapping: Record<WorkdayStatus, IconNames> = {
  NotWorking: 'calendarX',
  OfficeDay: 'office',
  WorkingRemotely: 'remote',
};

export const resolveWorkdayStatusIcon = (loading: boolean, workday: Workday | undefined): IconNames => {
  if (loading) return 'dots';
  if (!workday) return 'locationUnknown';
  return IconStatusMapping[workday.status];
};
