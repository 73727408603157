import {OnboardingCard} from '../OnboardingCard';
import {Input} from '@molecules';
import {OnboardingItemCell, LabelText} from '../styles';
import {useTranslation} from 'react-i18next';
import {getDefaultBuildingId, getOnboardingState, useAppSelector} from '@lib/store';
import {OnboardingStep} from '../types';
import {useDeskAmenityOptions} from '@lib/utils/src/hooks/preferences';

interface OnboardingDeskAmenitiesProps {
  areaId?: string | null;
  initialValue: string[];
  onBack?: () => void;
  onSelectDeskAmenities: (selectedDeskAmenities: string[]) => void;
}

export const OnboardingDeskAmenitiesCard = ({
  initialValue = [],
  areaId = null,
  onBack,
  onSelectDeskAmenities,
}: OnboardingDeskAmenitiesProps) => {
  const {t} = useTranslation();
  const buildingId = useAppSelector(getDefaultBuildingId);
  const {deskAmenities} = useAppSelector(getOnboardingState);

  const {deskAmenityOptions, deskAmenityOptionsOutsideOfArea} = useDeskAmenityOptions({buildingId, areaId});

  return (
    <OnboardingCard
      items={deskAmenityOptions}
      title={t('workplace:WorkspacePreference')}
      description={t('workplace:WorkspacePreferenceDescription')}
      currentStep={OnboardingStep.DeskAmenities}
      emptyText={t('workplace:NoDeskAmenities')}
      initialValue={initialValue}
      renderItem={({item: deskAmenity, selectedItem, setSelectedItem, handleOnFocus}) => (
        <OnboardingItemCell
          key={deskAmenity.value}
          onFocus={handleOnFocus}>
          <Input
            type="checkbox"
            name="deskAmenity"
            value={deskAmenity.value}
            checked={selectedItem?.includes(deskAmenity.value)}
            onChange={() => {
              const previousAmenities = selectedItem ?? [];
              const newAmenity = deskAmenity.value;

              const updatedAmenities = previousAmenities.includes(newAmenity)
                ? previousAmenities.filter((item) => item !== newAmenity)
                : [...previousAmenities, newAmenity];

              setSelectedItem(updatedAmenities);
            }}
          />
          <LabelText>{deskAmenity.label}</LabelText>
        </OnboardingItemCell>
      )}
      onBack={() => onBack?.()}
      onSelect={onSelectDeskAmenities}
    />
  );
};
