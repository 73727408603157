import {OnboardingCard} from '../OnboardingCard';
import {Input} from '@molecules';
import {OnboardingItemCell, LabelText} from '../styles';
import {useTranslation} from 'react-i18next';
import {Building, getDefaultBuildingId, getIsUpdatingDefaultBuilding, useAppSelector} from '@lib/store';
import {OnboardingStep} from '../types';

export type OnboardingBuildingsCardProps = {
  buildings: Building[];
  onSelectBuilding: (buildingId?: string) => void;
};

export const OnboardingBuildingsCardNew = ({buildings, onSelectBuilding}: OnboardingBuildingsCardProps) => {
  const isUpdatingDefaultBuilding = useAppSelector(getIsUpdatingDefaultBuilding);
  const {t} = useTranslation();

  const defaultBuildingId = useAppSelector(getDefaultBuildingId);

  return (
    <OnboardingCard
      items={buildings}
      title={t('profile:WhichOfficeToSetAsDefault')}
      description={t('profile:WhichOfficeToSetAsDefault')}
      currentStep={OnboardingStep.Building}
      initialValue={defaultBuildingId}
      isSaving={isUpdatingDefaultBuilding}
      disableWhenNoSelection
      renderItem={({item: building, selectedItem = defaultBuildingId, setSelectedItem, handleOnFocus}) => (
        <OnboardingItemCell
          key={building.id}
          onFocus={handleOnFocus}>
          <Input
            type="radio"
            name="building"
            value={building.id}
            checked={selectedItem === building.id}
            onChange={() => setSelectedItem(building.id)}
          />
          <LabelText>{building.name}</LabelText>
        </OnboardingItemCell>
      )}
      onSelect={onSelectBuilding}
    />
  );
};
