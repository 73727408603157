export enum OnboardingStep {
  Welcome,
  Intro,
  Building,
  Floor,
  Area,
  DeskAmenities,
  ParkingLot,
  ParkingZone,
  Overview,
}
