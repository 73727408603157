import {useEffect, useState} from 'react';

import {
  dismissUserAnnouncement,
  getUserAnnouncements,
  handleError,
  selectAllMeetingSuggestionsWithOrganizerAttendees,
  useAppDispatch,
  useAppSelector,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {EmployeeCommunicationAnnouncement} from './EmployeeCommunicationAnnouncement';

type Props = {};

export const EmployeeCommunicationAnnouncementTile = () => {
  const dispatch = useAppDispatch();
  const announcements = useAppSelector(selectAllMeetingSuggestionsWithOrganizerAttendees);

  // used for dirty optimistic update
  const [internalDismissedAnnouncementId, setInternalDismissedAnnouncementId] = useState<string | null>(null);

  useEffect(() => {
    // initial data fetch handled here
    // rest is handled by getUserAnnouncementsListeners.ts
    dispatch(getUserAnnouncements());
  }, [dispatch]);

  // is sorted by BE. Latest announcement is first entry
  const activeNotification = announcements.filter(({id}) => id !== internalDismissedAnnouncementId)[0];

  if (!activeNotification) return null;

  const handleDismiss = async () => {
    setInternalDismissedAnnouncementId(activeNotification.id);

    const {success, result} = await dispatch(
      withAsyncThunkErrorHandling(() => dismissUserAnnouncement({id: activeNotification.id})),
    );

    if (success) {
      await dispatch(getUserAnnouncements());
    } else {
      handleError(result, dispatch);
    }
    setInternalDismissedAnnouncementId(null);
  };

  return (
    <EmployeeCommunicationAnnouncement
      handleDismiss={handleDismiss}
      title={activeNotification.title}
      content={activeNotification.content}
      link={activeNotification.link}
    />
  );
};
