import {Card} from '@atoms';
import {DialogLayout} from '@templates';
import styled from 'styled-components';

export const StyledDialogLayout = styled(DialogLayout)`
  background: none;
  border: none;
  display: grid;
  padding: 0;
  pointer-events: all;
`;

export const StyledCard = styled(Card)`
  align-content: space-between;
  display: grid;
  gap: 32px 0;
  justify-items: center;
  padding: var(--padding-y) 32px;
`;

export const IconAndContentWrapper = styled.div`
  align-content: space-between;
  display: grid;
  gap: 16px 0;
  justify-items: center;
  text-align: center;
`;

export const IconWrapper = styled.div<{
  $destructable: boolean;
}>`
  background: ${({$destructable, theme}) => ($destructable ? theme.validation.error : theme.background.white)};
  border-radius: 9999px;
  padding: 10px;
`;

export const ContentWrapper = styled.div`
  display: grid;
  gap: 8px 0;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0 16px;
  justify-content: center;
  width: 100%;
`;
