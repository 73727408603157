import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

export const Layout = styled.div`
  --header-height: 60px;
  --footer-height: 0px;
  --body-height: calc(var(--100vh, 100vh) - var(--header-height, 60px) - var(--footer-height, 0px));
  display: grid;
  grid-template-columns: [fullbleed-start canvas-start] 1fr [content-start] 640px [content-end] 1fr [canvas-end fullbleed-end];
  grid-template-rows: [header-start] var(--header-height) [header-end fullbody-start canvas-start] 16px [heading-start] auto [heading-end] 32px [body-start] 1fr [body-end canvas-end fullbody-end];
  min-height: var(--100vh, 100vh);

  ${BREAKPOINTS.medium`
  grid-template-columns: [fullbleed-start canvas-start] 1fr [content-start] 500px [content-end] 1fr [canvas-end fullbleed-end];
  grid-template-rows: [header-start] var(--header-height) [header-end fullbody-start canvas-start] 16px [heading-start] auto [heading-end] 32px [body-start] 1fr [body-end canvas-end fullbody-end];
  `}

  ${BREAKPOINTS.small`
    --footer-height: 64px;
    grid-template-columns: [fullbleed-start canvas-start content-start] 100% [content-end canvas-end fullbleed-end];
    grid-template-rows:[header-start] var(--header-height) [header-end canvas-start fullbody-start body-start] 1fr [body-end fullbody-end canvas-end footer-start] var(--footer-height, 64px) [footer-end];
  `}
`;
