import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

export const CalendarLayout = styled.div`
  display: grid;
  grid-area: fullbody / fullbleed;
  grid-template-columns: [fullbleed-start calendar-start] 375px [calendar-end overview-start] 1fr [overview-end fullbleed-end];
  grid-template-rows: [fullbleed-start calendar-start overview-start] 1fr [calendar-end overview-end fullbleed-end];

  ${BREAKPOINTS.medium`
    grid-template-rows: [fullbleed-start calendar-start overview-start] 1fr [calendar-end overview-end fullbleed-end];
    grid-template-columns: [calendar-start fullbleed-start] 1fr [fullbleed-end calendar-end];
  `}

  ${BREAKPOINTS.small`
    grid-template-columns: [fullbleed-start calendar-start] 375px [calendar-end overview-start] 1fr [overview-end fullbleed-end];
    grid-template-rows: [fullbleed-start calendar-start overview-start] 1fr [calendar-end overview-end fullbleed-end];
  `}
`;
