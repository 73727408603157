import {useTranslation} from 'react-i18next';
import {getIsParkingFeatureVisible, useAppSelector} from '@lib/store';
import {H1, P} from '@quarks';
import {Icon, IconNames} from '@atoms';
import {OnboardingStep} from '../types';
import {cn} from '@utils';

const steps = [
  OnboardingStep.Building,
  OnboardingStep.Floor,
  OnboardingStep.Area,
  OnboardingStep.DeskAmenities,
  OnboardingStep.ParkingLot,
  OnboardingStep.ParkingZone,
];

const icons = {
  [OnboardingStep.Building]: 'office',
  [OnboardingStep.Floor]: 'floor',
  [OnboardingStep.Area]: 'area',
  [OnboardingStep.DeskAmenities]: 'desk',
  [OnboardingStep.ParkingLot]: 'car',
  [OnboardingStep.ParkingZone]: 'car',
} as Record<OnboardingStep, IconNames>;

interface Props {
  currentStep?: OnboardingStep;
  showProgress?: boolean;
}

export const OnboardingProgress = ({currentStep, showProgress}: Props) => {
  const {t} = useTranslation();
  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisible);

  const availableSteps = isParkingFeatureVisible
    ? steps
    : steps.filter((step) => step !== OnboardingStep.ParkingLot && step !== OnboardingStep.ParkingZone);

  const currentStepIndex = availableSteps.indexOf(currentStep || OnboardingStep.Building);
  const totalStepsCount = availableSteps.length;

  const labels = {
    [OnboardingStep.Building]: t('workplace:Office'),
    [OnboardingStep.Floor]: t('workplace:Floor'),
    [OnboardingStep.Area]: t('workplace:Area'),
    [OnboardingStep.DeskAmenities]: t('screen:DeskMap'),
    [OnboardingStep.ParkingLot]: t('parking:ParkingLot'),
    [OnboardingStep.ParkingZone]: t('parking:ParkingZone'),
  } as Record<OnboardingStep, string>;

  return (
    <div className="w-full flex flex-col mb-5">
      <div
        className={cn('flex flex-row items-end justify-between mb-2', [
          {
            'mb-0': !showProgress,
          },
        ])}>
        {currentStep !== undefined && (
          <div className="flex flex-row justify-between gap-3">
            <Icon
              icon={icons[currentStep]}
              size="2rem"
            />
            <H1>{labels[currentStep]}</H1>
          </div>
        )}

        {showProgress && (
          <P
            className="text-mapiq-black-800*"
            fontSize="sm">
            Step {currentStepIndex + 1}/{totalStepsCount}
          </P>
        )}
      </div>

      {showProgress && (
        <div className="flex justify-between gap-3 mt-2">
          {availableSteps.map((step, index) => (
            <div
              key={step}
              className={cn([
                'w-full h-0.5 bg-mapiq-black-200',
                {
                  'bg-green-600': index <= currentStepIndex,
                },
              ])}
            />
          ))}
        </div>
      )}
    </div>
  );
};
