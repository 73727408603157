import {useState, type MouseEvent} from 'react';
import {CalendarWeekDayProps, ErrorObject} from './types';
import {useParams} from 'react-router-dom';

import {
  useAppSelector,
  getSortedConnectedUsersWithOfficeDayOnDate,
  getStartOfTodayISOString,
  getWorkdayByDate,
  getAreAllNodesClosed,
  WorkdayStatus,
} from '@lib/store';
import {formatDate, isBeforeToday} from '@lib/utils';

import {isSameDay, parseISO, startOfToday} from 'date-fns';

import {Date} from '@atoms';
import {AvatarStack} from '@molecules';
import {cn} from '@utils';
import {CalendarCardCell} from './styles';
import {generateTestIdCoreString} from '@utils';
import {QuickOfficeDayButton} from './components/QuickOfficeDayButton';
import {QuickWorkingRemotelyButton} from './components/QuickWorkingRemotelyButton';
import {QuickNotWorkingButton} from './components/QuickNotWorkingButton';

export const CalendarWeekDay_ExperienceImprovements = ({
  date: dateAsString,
  isToday,
  toggleDayAndWeekView,
  showConnectionsWithIds,
  ...props
}: CalendarWeekDayProps) => {
  const TEST_ID = generateTestIdCoreString(import.meta.url);

  const dateAsDate = parseISO(dateAsString);
  const todayISOString = useAppSelector(getStartOfTodayISOString);
  const today = todayISOString ? parseISO(todayISOString) : startOfToday();
  const isPast = isBeforeToday(parseISO(dateAsString), today);
  const colleagues = useAppSelector((state) => getSortedConnectedUsersWithOfficeDayOnDate(state, dateAsString));

  const workday = useAppSelector((state) => getWorkdayByDate(state, dateAsString));

  const params = useParams();
  const activeDate = params.date ? params.date : startOfToday().toISOString();
  const isActive = isSameDay(dateAsDate, parseISO(activeDate));

  const areAllNodesClosed = useAppSelector((state) => getAreAllNodesClosed(state, dateAsDate));
  const dayOverviewLink = `/calendar/${formatDate(parseISO(dateAsString), 'yyyy-MM-dd', 'en')}`;

  const filteredColleagues = colleagues.filter(({id}) =>
    showConnectionsWithIds.length ? showConnectionsWithIds.includes(id) : true,
  );

  const [loadingWorkplace, setLoadingWorkplace] = useState(false);
  const [loadingParking, setLoadingParking] = useState(false);
  const [errorObject, setErrorObject] = useState<ErrorObject>({
    workday: null,
    workplace: null,
    parking: null,
  });
  const [processingFor, setProcessingFor] = useState<WorkdayStatus | null>(null);

  const selectedWorkdayStatus = workday?.status || processingFor;

  const error = errorObject.workday || errorObject.workplace || errorObject.parking;

  return (
    <CalendarCardCell
      className={cn('border-none transition-colors py-6 px-5', {
        'bg-mapiq-black-50': isActive,
      })}
      $isDisabled={isPast}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        if (error) {
          setErrorObject((p) => ({
            workday: null,
            workplace: null,
            parking: null,
          }));
        }
        if (isPast) {
          e.preventDefault();
        } else {
          toggleDayAndWeekView();
        }
      }}
      to={dayOverviewLink}
      aria-disabled={isPast}
      tabIndex={isPast ? -1 : 0}
      data-testid={`${TEST_ID}-card-cell-${dateAsString.split('T')[0]}`}
      {...props}>
      <div className="w-full flex gap-2 flex-col">
        <div className="flex gap-4 items-center justify-between">
          <h4>
            <Date
              date={dateAsString}
              format="EEEE"
            />
          </h4>

          <h4 className="text-mapiq-black-500">
            <Date
              date={dateAsString}
              format="d MMM"
            />
          </h4>
        </div>

        <div className="flex gap-4 items-center justify-between">
          <div className="flex gap-3 items-center">
            {(selectedWorkdayStatus === 'OfficeDay' || selectedWorkdayStatus === null) && (
              <QuickOfficeDayButton
                disabled={isPast || areAllNodesClosed}
                date={dateAsString}
                processingFor={processingFor}
                setProcessingFor={setProcessingFor}
                loadingWorkplace={loadingWorkplace}
                setLoadingWorkplace={setLoadingWorkplace}
                loadingParking={loadingParking}
                setLoadingParking={setLoadingParking}
                errorObject={errorObject}
                setErrorObject={setErrorObject}
              />
            )}
            {(selectedWorkdayStatus === 'WorkingRemotely' || selectedWorkdayStatus === null) && (
              <QuickWorkingRemotelyButton
                disabled={isPast}
                date={dateAsString}
                processingFor={processingFor}
                setProcessingFor={setProcessingFor}
                loading={loadingWorkplace}
                setLoading={setLoadingWorkplace}
                errorObject={errorObject}
                setErrorObject={setErrorObject}
              />
            )}

            {(selectedWorkdayStatus === 'NotWorking' || selectedWorkdayStatus === null) && (
              <QuickNotWorkingButton
                disabled={isPast}
                date={dateAsString}
                processingFor={processingFor}
                setProcessingFor={setProcessingFor}
                loading={loadingWorkplace}
                setLoading={setLoadingWorkplace}
                errorObject={errorObject}
                setErrorObject={setErrorObject}
              />
            )}
          </div>

          <div className="flex items-center relative">
            <small
              className={cn('text-functional-red z-10 absolute right-0 text-right text-nowrap', {
                'animate-in fade-in fill-mode-forwards': error,
                'animate-out fade-out fill-mode-forwards': !error,
              })}>
              {error}
            </small>

            <AvatarStack
              className={cn({
                'animate-in fade-in fill-mode-forwards': !error,
                'animate-out fade-out fill-mode-forwards pointer-events-none': error,
              })}
              date={dateAsString}
              disabled={isPast}
              users={filteredColleagues}
              tooltipId={`CalendarWeekDay-${dateAsString}`}
              overlap={true}
              max={3}
            />
          </div>
        </div>
      </div>
    </CalendarCardCell>
  );
};
