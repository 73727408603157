import {CardProps} from '@atoms';
import {useTranslation} from 'react-i18next';
import {
  CalendarSyncCell,
  DefaultOfficeCell,
  LanguageCell,
  ThemeCell,
  EmailNotificationCell,
  WorkingHoursCell,
  LicensePlateCell,
  BookingPreferencesCell,
} from '@organisms';
import {H2} from '@quarks';
import {CellWrapper, ProfileCard} from '../styles';
import {
  getHasUserGivenCalendarConsent,
  getIsExperienceImprovementsEnabled,
  getIsHybridMeetingsFeatureEnabled,
  getIsMeetingsCalendarEnabled,
  useAppSelector,
} from '@lib/store';

export const PreferencesCard = ({className, style, ...props}: CardProps) => {
  const {t} = useTranslation('profile');
  const isHybridMeetingEnabled = useAppSelector(getIsHybridMeetingsFeatureEnabled);
  const isExperienceImprovementsEnabled = useAppSelector(getIsExperienceImprovementsEnabled);
  const isMeetingsCalendarEnabled = useAppSelector(getIsMeetingsCalendarEnabled);
  const isCalendarConsentGiven = useAppSelector(getHasUserGivenCalendarConsent);

  const shouldShowWorkingHours = isHybridMeetingEnabled && isMeetingsCalendarEnabled && isCalendarConsentGiven;

  return (
    <ProfileCard
      as="section"
      className={className}
      style={style}
      {...props}>
      <H2>{t('Preferences')}</H2>
      <CellWrapper>
        {shouldShowWorkingHours && <WorkingHoursCell />}

        {isExperienceImprovementsEnabled ? <BookingPreferencesCell /> : <DefaultOfficeCell />}

        <LanguageCell />
        <LicensePlateCell />
        <ThemeCell />
        <CalendarSyncCell />
        <EmailNotificationCell />
      </CellWrapper>
    </ProfileCard>
  );
};
