import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
  withAsyncThunkErrorHandling,
  getAllBuildings,
  getDefaultBuildingId,
  getProfileConfig,
  savePreferredBuilding,
  getFloorsByBuildingId,
  getPreferences,
  getFilteredAndSortedParkingLots,
  loadParkingLotsByBuilding,
  getIsLoadingParkingLots,
  getAreaById,
  getAllParkingZones,
  updateWorkspaceLocationPreferences,
  updateParkingLocationPreferences,
  DeskAmenity,
  getAreasByFloorId,
  getIsParkingFeatureVisible,
} from '@lib/store';
import {useModal} from '@hooks';
import {ModalPage, ModalPageBottom} from '@organisms';
import {Subtitle} from '@quarks';
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from '@ui';
import {Toggle} from '@atoms';
import {cn} from '@utils';
import {Input} from '@molecules';
import isEqual from 'lodash/isEqual';

export const BookingPreferencesModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {closeModal} = useModal();

  const [loading, setLoading] = useState(false);

  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisible);
  const defaultBuilding = useAppSelector(getDefaultBuildingId);
  const allPreferences = useAppSelector(getPreferences);

  const isLoadingParkingLots = useAppSelector(getIsLoadingParkingLots);

  const {isBuildingSelectorShown} = useAppSelector(getProfileConfig);
  const buildings = useAppSelector(getAllBuildings);
  const parkingLots = useAppSelector(getFilteredAndSortedParkingLots);

  const parkingZones = useAppSelector(getAllParkingZones);

  const initialPreferences = {
    defaultBuilding,
    workspaceLocation: {
      floorId: allPreferences.workspaceLocation?.floorId || null,
      areaId: allPreferences.workspaceLocation?.areaId || null,
      isDeskRequired: allPreferences.workspaceLocation?.isDeskRequired || false,
      deskAmenities: allPreferences.workspaceLocation?.deskAmenities || [],
    },
    parkingLocation: {
      parkingLotId: allPreferences.parkingLocation?.parkingLotId || null,
      parkingZoneId: allPreferences.parkingLocation?.parkingZoneId || null,
    },
  };

  const [selectedBuilding, setSelectedBuilding] = useState(initialPreferences.defaultBuilding);
  const [selectedFloor, setSelectedFloor] = useState(initialPreferences.workspaceLocation.floorId);
  const [selectedArea, setSelectedArea] = useState(initialPreferences.workspaceLocation.areaId);
  const [isDeskRequired, setIsDeskRequired] = useState(initialPreferences.workspaceLocation.isDeskRequired);
  const [selectedParkingLot, setSelectedParkingLot] = useState(initialPreferences.parkingLocation.parkingLotId);
  const [selectedParkingZone, setSelectedParkingZone] = useState(initialPreferences.parkingLocation.parkingZoneId);
  const [selectedDeskAmenity, setSelectedDeskAmenity] = useState(
    initialPreferences.workspaceLocation.deskAmenities || [],
  );

  const floors = useAppSelector((state) => getFloorsByBuildingId(state, selectedBuilding));
  const areas = useAppSelector((state) => getAreasByFloorId(state, selectedFloor || '')).filter(
    (area) => area.capacity > 0,
  );
  const area = useAppSelector((state) => getAreaById(state, selectedArea || ''));

  const areaDeskAmenities = [...(area?.deskAmenities ?? [])];
  const allAreaDeskAmenities = Array.from(new Set(areas.flatMap(({deskAmenities}) => deskAmenities)));

  const selectionBasedDeskAmenities = selectedArea ? areaDeskAmenities : selectedFloor ? allAreaDeskAmenities : [];
  const deskAmenityOptions = selectionBasedDeskAmenities
    .sort((a, b) => a.localeCompare(b))
    .map((deskAmenity) => ({
      value: deskAmenity,
      checked: selectedDeskAmenity.includes(deskAmenity),
      label: t(`deskAmenities:deskAmenity`, {context: deskAmenity}),
    }));

  const parkingZoneOptions = [
    ...parkingZones
      .filter((zone) => zone.parentId === selectedParkingLot)
      .map((zone) => ({
        value: zone.id,
        label: zone.name,
      })),
  ];

  const preferencesToSave = {
    defaultBuilding: selectedBuilding,
    workspaceLocation: {
      floorId: selectedFloor,
      areaId: selectedArea,
      isDeskRequired,
      deskAmenities: selectedDeskAmenity.filter((a) => selectionBasedDeskAmenities.includes(a as DeskAmenity)),
    },
    parkingLocation: {
      parkingLotId: selectedParkingLot,
      parkingZoneId: selectedParkingZone,
    },
  };

  const isDirty = {
    defaultBuilding: selectedBuilding !== defaultBuilding,
    workspaceLocation: !isEqual(initialPreferences.workspaceLocation, preferencesToSave.workspaceLocation),
    parkingLocation: !isEqual(initialPreferences.parkingLocation, preferencesToSave.parkingLocation),
  };

  const canSave = Object.values(isDirty).some((value) => value);

  useEffect(() => {
    if (defaultBuilding) {
      setSelectedBuilding(defaultBuilding);
    }
  }, [defaultBuilding]);

  const handleSelectBuilding = (building: string | null) => {
    if (!building) return;
    setSelectedBuilding(building);

    setSelectedFloor(null);
    setSelectedArea(null);
    setSelectedParkingLot(null);
    setSelectedParkingZone(null);
  };

  const handleSelectFloor = (floorId: string | null) => {
    setSelectedFloor(floorId);
    setSelectedArea(null);
  };

  const handleSelectArea = (areaId: string | null) => {
    setSelectedArea(areaId);
  };
  const handleSelectParkingLot = (parkingLotId: string | null) => {
    setSelectedParkingLot(parkingLotId);
    setSelectedParkingZone(null);
  };
  const handleSelectedparkingZone = (parkingZoneId: string | null) => {
    setSelectedParkingZone(parkingZoneId);
  };

  const handleSelectDeskAmenity = (deskAmenity: string) => {
    if (selectedDeskAmenity.includes(deskAmenity)) {
      setSelectedDeskAmenity(selectedDeskAmenity.filter((item) => item !== deskAmenity));
    } else {
      setSelectedDeskAmenity([...selectedDeskAmenity, deskAmenity]);
    }
  };

  const handleConfirm = () => {
    if (!canSave) return;
    setLoading(true);
    Promise.allSettled([
      isDirty.defaultBuilding &&
        dispatch(
          withAsyncThunkErrorHandling(() => savePreferredBuilding({buildingId: preferencesToSave.defaultBuilding})),
        ),
      isDirty.workspaceLocation &&
        dispatch(
          withAsyncThunkErrorHandling(() => updateWorkspaceLocationPreferences(preferencesToSave.workspaceLocation)),
        ),
      isDirty.parkingLocation &&
        dispatch(
          withAsyncThunkErrorHandling(() => updateParkingLocationPreferences(preferencesToSave.parkingLocation)),
        ),
    ])
      .then(() => {
        closeModal();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedBuilding) {
      dispatch(
        withAsyncThunkErrorHandling(() =>
          loadParkingLotsByBuilding({
            buildingId: selectedBuilding,
          }),
        ),
      );
    }
  }, [dispatch, selectedBuilding]);

  const parkingLotOptions = [
    ...parkingLots.map((lot) => ({
      value: lot.id,
      label: lot.name,
    })),
  ];

  const modalPageRef = useRef<HTMLDivElement>(null);

  return (
    <ModalPage
      bottom={
        <ModalPageBottom
          buttonLoading={loading}
          onClick={handleConfirm}
          buttonDisabled={!canSave}
          buttonLabel={t('translation:Save')}
        />
      }
      onClose={closeModal}
      title={t('translation:BookingPreferences')}>
      <div
        ref={modalPageRef}
        className={cn('grid gap-6 [&>label]:grid [&>label]:gap-1')}>
        {isBuildingSelectorShown && (
          <label className="font-semibold grid gap-2">
            {t('workplace:Office')}

            <Select
              value={selectedBuilding}
              onValueChange={handleSelectBuilding}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent>
                {buildings.map((building) => (
                  <SelectItem
                    key={building.id}
                    value={building.id}>
                    {building.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </label>
        )}

        {isParkingFeatureVisible && (
          <div
            className={cn('grid gap-4', {
              'grid-cols-2': parkingZoneOptions.length,
            })}>
            <label className="font-semibold grid grid-cols-subgrid">
              {t('parking:ParkingLot')}
              <Select
                disabled={isLoadingParkingLots}
                value={selectedParkingLot}
                onValueChange={handleSelectParkingLot}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Theme" />
                </SelectTrigger>
                <SelectContent>
                  {parkingLotOptions.map(({value, label}) => (
                    <SelectItem
                      key={value}
                      value={value}>
                      {label}
                    </SelectItem>
                  ))}
                  <SelectItem value={null}>{t('parking:ManualSelection')}</SelectItem>
                </SelectContent>
              </Select>
            </label>

            {!!parkingZoneOptions.length && (
              <label className="font-semibold grid grid-cols-subgrid">
                {t('parking:ParkingZone')}
                <Select
                  disabled={isLoadingParkingLots}
                  value={selectedParkingZone}
                  onValueChange={handleSelectedparkingZone}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Theme" />
                  </SelectTrigger>
                  <SelectContent>
                    {parkingZoneOptions.map(({value, label}) => (
                      <SelectItem
                        key={value}
                        value={value}>
                        {label}
                      </SelectItem>
                    ))}
                    <SelectItem value={null}>{t('parking:ManualSelection')}</SelectItem>
                  </SelectContent>
                </Select>
              </label>
            )}
          </div>
        )}

        <label className="font-semibold grid ">
          {t('workplace:Workspace')}
          <div className="grid grid-cols-[1fr_2fr] gap-4">
            <Select
              value={selectedFloor}
              onValueChange={handleSelectFloor}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent>
                {floors.map((floor) => (
                  <SelectItem
                    key={floor.id}
                    value={floor.id}>
                    {floor.name}
                  </SelectItem>
                ))}
                <SelectItem value={null}>{t('workplace:NoPreferredFloor')}</SelectItem>
              </SelectContent>
            </Select>

            <Select
              value={selectedArea}
              onValueChange={handleSelectArea}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent>
                {areas.map((area) => (
                  <SelectItem
                    key={area.id}
                    value={area.id}>
                    {area.name}
                  </SelectItem>
                ))}
                <SelectItem value={null}>{t('workplace:NoPreferredArea')}</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </label>

        <label className="font-semibold items-center grid grid-cols-[1fr_auto]">
          <span>
            {t('workplace:AutomaticDeskBooking')}
            <Subtitle className="text-xs text-mapiq-black-500">{t('workplace:AutomaticDeskBookingLabel')}</Subtitle>
          </span>
          <Toggle
            className="justify-self-end"
            checked={isDeskRequired}
            onChange={(e) => setIsDeskRequired(e.currentTarget.checked)}></Toggle>
        </label>

        <div className="font-semibold items-center grid gap-6">
          {t('deskAmenities:deskAmenity_plural')}
          <div className="grid grid-cols-2 gap-6 ">
            {deskAmenityOptions.length === 0 && (
              <p className="col-span-full font-normal text-mapiq-black-500">{t('workplace:NoDeskAmenities')}</p>
            )}
            {deskAmenityOptions.map(({value, checked, label}) => (
              <label
                key={value}
                className="flex gap-6 items-center font-normal">
                <Input
                  type="checkbox"
                  checked={checked}
                  onChange={() => {
                    handleSelectDeskAmenity(value);
                  }}
                />
                {label}
              </label>
            ))}
          </div>
        </div>
      </div>
    </ModalPage>
  );
};
