import {
  withAsyncThunkErrorHandling,
  savePreferredBuilding,
  setWorkspaceLocation,
  setParkingLocation,
  useAppDispatch,
  useAppSelector,
  getOnboardingState,
  getIsParkingFeatureVisible,
  getUser,
  getIsExperienceImprovementsEnabled,
  setIsDeskRequired as setDeskRequired,
} from '@lib/store';
import {useState} from 'react';
import {OnboardingStep} from 'src/components/organisms/onboarding/types';

export const useOnboarding = () => {
  const dispatch = useAppDispatch();
  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisible);
  const user = useAppSelector(getUser);
  const isExperienceImprovementsEnabled = useAppSelector(getIsExperienceImprovementsEnabled);

  const {floorId, areaId, deskAmenities, parkingLotId, parkingZoneId} = useAppSelector(getOnboardingState);

  const [step, setStep] = useState(OnboardingStep.Welcome);

  const saveBuilding = async (buildingId?: string | null) => {
    if (buildingId === undefined) return;

    const isNew = !!isExperienceImprovementsEnabled;

    const result = await dispatch(withAsyncThunkErrorHandling(() => savePreferredBuilding({buildingId, isNew, user})));

    if (!result.success) return;

    if (isExperienceImprovementsEnabled) {
      setStep(OnboardingStep.Floor);
    }
  };

  const setFloor = (selectedFloorId: string | null) => {
    dispatch(setWorkspaceLocation({floorId: selectedFloorId, areaId, deskAmenities}));

    // If user has no floor selected, skip area and desk amenities selection
    if (!selectedFloorId) {
      if (isParkingFeatureVisible) {
        setStep(OnboardingStep.ParkingLot);
        return;
      }

      setStep(OnboardingStep.Overview);
      return;
    }

    setStep(OnboardingStep.Area);
  };

  const setArea = (selectedAreaId: string | null) => {
    dispatch(setWorkspaceLocation({floorId, areaId: selectedAreaId, deskAmenities}));

    // If user has no area selected, skip desk amenities selection
    if (!selectedAreaId) {
      if (isParkingFeatureVisible) {
        setStep(OnboardingStep.ParkingLot);
        return;
      }

      setStep(OnboardingStep.Overview);
      return;
    }

    setStep(OnboardingStep.DeskAmenities);
  };

  const setDeskAmenities = (selectedDeskAmenities: string[]) => {
    dispatch(setWorkspaceLocation({floorId, areaId, deskAmenities: selectedDeskAmenities}));

    if (isParkingFeatureVisible) {
      setStep(OnboardingStep.ParkingLot);
      return;
    }

    setStep(OnboardingStep.Overview);
  };

  const setParkingLot = (selectedParkingLotId: string | null) => {
    dispatch(setParkingLocation({parkingLotId: selectedParkingLotId, parkingZoneId}));

    if (!selectedParkingLotId) {
      setStep(OnboardingStep.Overview);
      return;
    }

    setStep(OnboardingStep.ParkingZone);
  };

  const setParkingZone = (selectedParkingZoneId: string | null) => {
    dispatch(setParkingLocation({parkingLotId, parkingZoneId: selectedParkingZoneId}));

    setStep(OnboardingStep.Overview);
  };

  const setIsDeskRequired = (value: boolean) => {
    dispatch(setDeskRequired(value));
  };

  return {
    step,
    setStep,

    saveBuilding,
    setFloor,
    setArea,
    setDeskAmenities,
    setParkingLot,
    setParkingZone,
    setIsDeskRequired,

    isParkingFeatureVisible,
  };
};
