import {PathProps} from './types';
import {DuotonePath, FilledPath, Path, Rect} from './styles';
import {generateRandomId} from '@utils';

export const icons = {
  addLocation: ({$color, $duotone, $weight}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M14.625 10.25H12M12 10.25H9.375M12 10.25V7.625M12 10.25V12.875M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75Z"
      />
    </>
  ),
  area: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M3.31 9.666a9.037 9.037 0 0 1 2.324-4.032m0 12.732a8.971 8.971 0 0 1-2.325-4.041m11.025 6.366a8.831 8.831 0 0 1-4.66 0m11.017-6.357a9.039 9.039 0 0 1-2.325 4.032m0-12.732a8.972 8.972 0 0 1 2.325 4.04M9.666 3.31a8.831 8.831 0 0 1 4.659 0"
      />
    </>
  ),
  arrowDown: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 3.75v16.5M5.25 13.5 12 20.25l6.75-6.75"
      />
    </>
  ),
  arrowLeft: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M20.25 12H3.75m6.75-6.75L3.75 12l6.75 6.75"
      />
    </>
  ),
  arrowRight: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M3.75 12h16.5M13.5 5.25 20.25 12l-6.75 6.75"
      />
    </>
  ),
  arrowUp: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 20.25V3.75M5.25 10.5 12 3.75l6.75 6.75"
      />
    </>
  ),
  bell: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M5.396 9.75c-.002-.89.17-1.77.504-2.591a6.75 6.75 0 0 1 1.442-2.195 6.606 6.606 0 0 1 2.16-1.46A6.505 6.505 0 0 1 12.046 3c3.643.028 6.558 3.113 6.558 6.834v.666c0 3.356.69 5.306 1.297 6.375a.76.76 0 0 1-.267 1.023.726.726 0 0 1-.367.102H4.733a.726.726 0 0 1-.635-.376.761.761 0 0 1 0-.749c.608-1.069 1.298-3.019 1.298-6.375v-.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M8.99 21h6M5.27 9.75A6.74 6.74 0 0 1 12.047 3c3.712.028 6.684 3.113 6.684 6.834v.666c0 3.356.703 5.306 1.322 6.375A.75.75 0 0 1 19.406 18H4.594a.75.75 0 0 1-.647-1.125c.619-1.069 1.322-3.019 1.322-6.375v-.75Z"
      />
    </>
  ),
  blinds: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M20.25 12H3.75M12 9V1.5M12 1.5L9 4.5M12 1.5L15 4.5M12 15V22.5M12 22.5L15 19.5M12 22.5L9 19.5"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  bluetooth: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="m17.25 7.5-6-4.5v18l6-4.5-6-4.5 6-4.5Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="m11.25 12 6-4.5-6-4.5v9Zm0 0 6 4.5-6 4.5v-9Zm0 0-6-4.5m6 4.5-6 4.5"
      />
    </>
  ),
  broadcast: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M7.228 16.772a6.712 6.712 0 0 1-1.444-2.147 6.712 6.712 0 0 1 0-5.25 6.713 6.713 0 0 1 1.444-2.147m9.544 0a6.713 6.713 0 0 1 1.444 2.147 6.713 6.713 0 0 1 0 5.25 6.713 6.713 0 0 1-1.444 2.147M4.575 19.425a10.586 10.586 0 0 1-2.25-3.338 10.538 10.538 0 0 1 0-8.175 10.585 10.585 0 0 1 2.25-3.337m14.85 0c.956.958 1.72 2.091 2.25 3.337a10.537 10.537 0 0 1 0 8.175 10.585 10.585 0 0 1-2.25 3.338M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </>
  ),
  camera: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M19.5 19.5H4.5C4.10218 19.5 3.72064 19.342 3.43934 19.0607C3.15804 18.7794 3 18.3978 3 18V7.5C3 7.10218 3.15804 6.72064 3.43934 6.43934C3.72064 6.15804 4.10218 6 4.5 6H7.5L9 3.75H15L16.5 6H19.5C19.8978 6 20.2794 6.15804 20.5607 6.43934C20.842 6.72064 21 7.10218 21 7.5V18C21 18.3978 20.842 18.7794 20.5607 19.0607C20.2794 19.342 19.8978 19.5 19.5 19.5Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M12 15.75C13.864 15.75 15.375 14.239 15.375 12.375C15.375 10.511 13.864 9 12 9C10.136 9 8.625 10.511 8.625 12.375C8.625 14.239 10.136 15.75 12 15.75Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  calendar: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M19.5 3.75h-15a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h15a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M16.5 2.25v3m-9-3v3m-3.75 3h16.5M4.5 3.75h15a.75.75 0 0 1 .75.75v15a.75.75 0 0 1-.75.75h-15a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 .75-.75Z"
      />
    </>
  ),
  calendarCheck: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M19.5 3.75h-15a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h15a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M16.5 2.25v3m-9-3v3m-3.75 3h16.5M15.375 12l-4.378 4.125-2.372-2.25M4.5 3.75h15a.75.75 0 0 1 .75.75v15a.75.75 0 0 1-.75.75h-15a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 .75-.75Z"
      />
    </>
  ),
  calendarEdit: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
        $duotone={$duotone}
      />
      <Path
        d="M16.5 2.25V5.25M7.5 2.25V5.25M12 11V13.75M12 16.5V16.6M3.75 8.25H20.25M4.5 3.75H19.5C19.9142 3.75 20.25 4.08579 20.25 4.5V19.5C20.25 19.9142 19.9142 20.25 19.5 20.25H4.5C4.08579 20.25 3.75 19.9142 3.75 19.5V4.5C3.75 4.08579 4.08579 3.75 4.5 3.75Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  calendarPlus: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M19.75 3.75h-15A.75.75 0 0 0 4 4.5v15c0 .414.336.75.75.75h15a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M16.5 2.25v3m-9-3v3m-3.75 3h16.5m-5.625 6h-5.25M12 11.625v5.25M4.5 3.75h15a.75.75 0 0 1 .75.75v15a.75.75 0 0 1-.75.75h-15a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 .75-.75Z"
      />
    </>
  ),
  calendarX: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M19.5 3.75h-15a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h15a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M16.5 2.25v3m-9-3v3m-3.75 3h16.5m-6 3.75-4.5 4.5m4.5 0L9.75 12M4.5 3.75h15a.75.75 0 0 1 .75.75v15a.75.75 0 0 1-.75.75h-15a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 .75-.75Z"
      />
    </>
  ),
  car: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="m6 4.5-3 7 18-.5-3-6.5H6Z"
      />
      <DuotonePath
        $duotone={$duotone}
        d="M3 11h18v6H3v-6Zm0 6h4v3H3v-3Zm14 0h4v3h-4v-3Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M1.5 11.25h21m-1.5 6v2.25a.75.75 0 0 1-.75.75H18a.75.75 0 0 1-.75-.75v-2.25m3.75 0H3m18 0v-6l-2.803-6.3a.75.75 0 0 0-.684-.45H6.486a.75.75 0 0 0-.684.45L3 11.25v6m3.75 0v2.25a.75.75 0 0 1-.75.75H3.75A.75.75 0 0 1 3 19.5v-2.25m3-3h1.5m9 0H18"
      />
    </>
  ),
  caretDown: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M19.5 9 12 16.5 4.5 9"
      />
    </>
  ),
  caretLeft: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M15 19.5 7.5 12 15 4.5"
      />
    </>
  ),
  caretRight: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="m9 4.5 7.5 7.5L9 19.5"
      />
    </>
  ),
  caretUp: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M4.5 15 12 7.5l7.5 7.5"
      />
    </>
  ),
  chair: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M11.548 2a3.941 3.941 0 0 0-3.917 4.376l.394 3.54a1 1 0 0 0 .994.89h5.059a1 1 0 0 0 .994-.89l.393-3.54A3.941 3.941 0 0 0 11.548 2Zm-3.693 8.807a1 1 0 0 0-.958 1.284l.525 1.774a1 1 0 0 0 .96.716h6.334a1 1 0 0 0 .959-.716l.525-1.774a1 1 0 0 0-.959-1.284H7.855Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M11.55 21.5v-6.29M6.516 21.5c0-3.774 5.032-3.774 5.032-3.774s5.033 0 5.033 3.774M19 10.806c0 3.786-4.306 3.775-4.306 3.775M4 10.806c0 3.786 4.403 3.775 4.403 3.775m.616-3.774h5.06a1 1 0 0 0 .993-.89l.393-3.54a3.941 3.941 0 1 0-7.834 0l.394 3.54a1 1 0 0 0 .994.89ZM8.38 14.58h6.335a1 1 0 0 0 .959-.716l.525-1.774a1 1 0 0 0-.958-1.284H7.856a1 1 0 0 0-.96 1.284l.526 1.774a1 1 0 0 0 .96.716Z"
      />
    </>
  ),
  chat: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M2.368 8.482a6.76 6.76 0 0 0 .829 4.709l-.666 2.334a.563.563 0 0 0 .694.694l2.334-.666a6.76 6.76 0 0 0 3.076.937 6.74 6.74 0 0 0 9.806 3.563l2.334.666a.563.563 0 0 0 .694-.694l-.666-2.334A6.741 6.741 0 0 0 15.39 7.51a6.76 6.76 0 0 0-13.022.97Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M8.634 16.49a6.74 6.74 0 0 0 9.807 3.563l2.334.666a.564.564 0 0 0 .694-.694l-.666-2.334a6.74 6.74 0 0 0-5.437-10.182M3.196 13.19a6.76 6.76 0 1 1 2.363 2.363l-2.334.666a.564.564 0 0 1-.694-.694l.666-2.335Z"
      />
    </>
  ),
  check: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="m20.25 6.75-10.5 10.5L4.5 12"
      />
    </>
  ),
  checkCirle: ({$color, $duotone, $weight}: PathProps) => (
    <>
      <DuotonePath
        $color={$color}
        $duotone={$duotone}
        $weight={$weight}
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M16.125 9.75L10.6219 15L7.875 12.375"
      />
    </>
  ),
  clock: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 6.75V12h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </>
  ),
  clockCounterClockwise: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 7.5V12"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M15.9 14.25L12 12"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M6.73145 9.34692H2.98145V5.59692"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M6.16895 17.8313C7.32246 18.9857 8.79246 19.7721 10.393 20.091C11.9935 20.4099 13.6527 20.2469 15.1605 19.6227C16.6684 18.9984 17.9573 17.941 18.8641 16.5842C19.7709 15.2273 20.255 13.632 20.255 12C20.255 10.368 19.7709 8.7727 18.8641 7.41585C17.9573 6.059 16.6684 5.00158 15.1605 4.37735C13.6527 3.75313 11.9935 3.59014 10.393 3.90902C8.79246 4.22789 7.32246 5.0143 6.16895 6.16875L2.98145 9.34688"
      />
    </>
  ),
  close: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="m18.75 5.25-13.5 13.5m13.5 0L5.25 5.25"
      />
    </>
  ),
  coffee: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M7.81 20.25A8.25 8.25 0 0 1 3 12.75v-4.5h16.5v4.5a8.25 8.25 0 0 1-4.81 7.5"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M8.25 2.25v3m3-3v3m3-3v3M3 20.25h16.5m-11.69 0A8.25 8.25 0 0 1 3 12.75v-4.5h16.5m0 0v4.5a8.25 8.25 0 0 1-4.81 7.5m4.81-12a3 3 0 0 1 3 3V12a3 3 0 0 1-3 3h-.319"
      />
    </>
  ),
  contacts: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        d="M20.25 20.25V3.75C20.25 3.33579 19.9142 3 19.5 3H6C5.58579 3 5.25 3.33579 5.25 3.75V20.25C5.25 20.6642 5.58579 21 6 21H19.5C19.9142 21 20.25 20.6642 20.25 20.25Z"
        $duotone={$duotone}
      />
      <Path
        d="M12.75 13.5C14.4069 13.5 15.75 12.1569 15.75 10.5C15.75 8.84315 14.4069 7.5 12.75 7.5C11.0931 7.5 9.75 8.84315 9.75 10.5C9.75 12.1569 11.0931 13.5 12.75 13.5Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M8.25 15.75C8.77395 15.0514 9.45336 14.4844 10.2344 14.0938C11.0155 13.7033 11.8767 13.5 12.75 13.5C13.6233 13.5 14.4845 13.7033 15.2656 14.0938C16.0466 14.4844 16.726 15.0514 17.25 15.75"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M12.75 13.5C14.4069 13.5 15.75 12.1569 15.75 10.5C15.75 8.84315 14.4069 7.5 12.75 7.5C11.0931 7.5 9.75 8.84315 9.75 10.5C9.75 12.1569 11.0931 13.5 12.75 13.5ZM12.75 13.5C11.8767 13.5 11.0155 13.7033 10.2344 14.0938C9.45336 14.4844 8.77395 15.0514 8.25 15.75M12.75 13.5C13.6233 13.5 14.4845 13.7033 15.2656 14.0938C16.0466 14.4844 16.726 15.0514 17.25 15.75M3 10.125H5.25M3 6.375H5.25M3 13.875H5.25M3 17.625H5.25M20.25 20.25V3.75C20.25 3.33579 19.9142 3 19.5 3H6C5.58579 3 5.25 3.33579 5.25 3.75V20.25C5.25 20.6642 5.58579 21 6 21H19.5C19.9142 21 20.25 20.6642 20.25 20.25Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  copy: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M20.25 15.75h-4.5v4.5h-12v-12h4.5v-4.5h12v12Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M15.75 15.75h4.5v-12h-12v4.5m-4.5 0h12v12h-12v-12Z"
      />
    </>
  ),
  dashboard: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $color={$color}
        $duotone={$duotone}
        $weight={$weight}
        d="M2.25 17.2499V15.1031C2.25 9.71245 6.58125 5.2687 11.9625 5.24995C13.246 5.24501 14.5179 5.49356 15.7052 5.98133C16.8924 6.46911 17.9717 7.1865 18.881 8.09237C19.7904 8.99823 20.5119 10.0747 21.0043 11.2601C21.4966 12.4455 21.75 13.7164 21.75 15V17.2499C21.75 17.4489 21.671 17.6396 21.5303 17.7803C21.3897 17.9209 21.1989 17.9999 21 17.9999H3C2.80109 17.9999 2.61032 17.9209 2.46967 17.7803C2.32902 17.6396 2.25 17.4489 2.25 17.2499Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 5.25V8.25"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M2.57812 12.478L5.48438 13.2562"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M21.4219 12.478L18.5156 13.2562"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M9.69385 18.0001L16.1063 9.64697"
      />
    </>
  ),
  dayView: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M3 9C3 8.44772 3.44772 8 4 8H20C20.5523 8 21 8.44772 21 9V19C21 19.5523 20.5523 20 20 20H4C3.44772 20 3 19.5523 3 19V9Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M3 3.75H21M4 20H20C20.5523 20 21 19.5523 21 19V9C21 8.44772 20.5523 8 20 8H4C3.44772 8 3 8.44772 3 9V19C3 19.5523 3.44772 20 4 20Z"
      />
    </>
  ),
  desk: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M7 4.5a.5.5 0 0 1 .5-.5H16a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H7.5a.5.5 0 0 1-.5-.5v-5Zm8.5 9a.5.5 0 0 1 .5-.5h4.5a.5.5 0 0 1 .5.5V16a.5.5 0 0 1-.5.5H16a.5.5 0 0 1-.5-.5v-2.5Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M2 13h20M3 13v7m18-7v7M7.5 10H16a.5.5 0 0 0 .5-.5v-5A.5.5 0 0 0 16 4H7.5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5Zm8.5 6.5h4.5a.5.5 0 0 0 .5-.5v-2.5a.5.5 0 0 0-.5-.5H16a.5.5 0 0 0-.5.5V16a.5.5 0 0 0 .5.5Z"
      />
    </>
  ),
  dots: ({$color, $weight}: PathProps) => (
    <>
      <FilledPath
        as="circle"
        cx="6"
        cy="12"
        r={$weight === 'regular' ? '1.5' : $weight === 'light' ? '1.25' : '1'}
        $color={$color}
      />
      <FilledPath
        as="circle"
        cx="12"
        cy="12"
        r={$weight === 'regular' ? '1.5' : $weight === 'light' ? '1.25' : '1'}
        $color={$color}
      />
      <FilledPath
        as="circle"
        cx="18"
        cy="12"
        r={$weight === 'regular' ? '1.5' : $weight === 'light' ? '1.25' : '1'}
        $color={$color}
      />
    </>
  ),
  download: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M8.0625 10.3125L12 14.25L15.9375 10.3125"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 3.75V14.25"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M20.25 14.25V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V14.25"
      />
    </>
  ),
  dotsVertical: ({$color, $weight}: PathProps) => (
    <>
      <FilledPath
        as="circle"
        cx="12"
        cy="6"
        r={$weight === 'regular' ? '1.5' : $weight === 'light' ? '1.25' : '1'}
        $color={$color}
      />
      <FilledPath
        as="circle"
        cx="12"
        cy="12"
        r={$weight === 'regular' ? '1.5' : $weight === 'light' ? '1.25' : '1'}
        $color={$color}
      />
      <FilledPath
        as="circle"
        cx="12"
        cy="18"
        r={$weight === 'regular' ? '1.5' : $weight === 'light' ? '1.25' : '1'}
        $color={$color}
      />
    </>
  ),
  elevator: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M19.5 3.75h-15a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h15a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="m15 13.5-3 3-3-3m0-3 3-3 3 3M4.5 3.75h15a.75.75 0 0 1 .75.75v15a.75.75 0 0 1-.75.75h-15a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 .75-.75Z"
      />
    </>
  ),
  extras: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        d="M7.125 10.5C8.98896 10.5 10.5 8.98896 10.5 7.125C10.5 5.26104 8.98896 3.75 7.125 3.75C5.26104 3.75 3.75 5.26104 3.75 7.125C3.75 8.98896 5.26104 10.5 7.125 10.5Z"
        $color={$color}
        $duotone={$duotone}
        $weight={$weight}
      />
      <DuotonePath
        d="M16.875 10.5C18.739 10.5 20.25 8.98896 20.25 7.125C20.25 5.26104 18.739 3.75 16.875 3.75C15.011 3.75 13.5 5.26104 13.5 7.125C13.5 8.98896 15.011 10.5 16.875 10.5Z"
        $color={$color}
        $duotone={$duotone}
        $weight={$weight}
      />
      <DuotonePath
        d="M7.125 20.25C8.98896 20.25 10.5 18.739 10.5 16.875C10.5 15.011 8.98896 13.5 7.125 13.5C5.26104 13.5 3.75 15.011 3.75 16.875C3.75 18.739 5.26104 20.25 7.125 20.25Z"
        $color={$color}
        $duotone={$duotone}
        $weight={$weight}
      />
      <Path
        d="M16.875 14.25V19.5M19.5 16.875H14.25M10.5 7.125C10.5 8.98896 8.98896 10.5 7.125 10.5C5.26104 10.5 3.75 8.98896 3.75 7.125C3.75 5.26104 5.26104 3.75 7.125 3.75C8.98896 3.75 10.5 5.26104 10.5 7.125ZM20.25 7.125C20.25 8.98896 18.739 10.5 16.875 10.5C15.011 10.5 13.5 8.98896 13.5 7.125C13.5 5.26104 15.011 3.75 16.875 3.75C18.739 3.75 20.25 5.26104 20.25 7.125ZM10.5 16.875C10.5 18.739 8.98896 20.25 7.125 20.25C5.26104 20.25 3.75 18.739 3.75 16.875C3.75 15.011 5.26104 13.5 7.125 13.5C8.98896 13.5 10.5 15.011 10.5 16.875Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  expand: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $weight={$weight}
        $color={$color}
        d="M15 4.5H19.5M19.5 4.5V9M19.5 4.5L14.25 9.75M9 19.5H4.5M4.5 19.5V15M4.5 19.5L9.75 14.25"
      />
    </>
  ),
  filter: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M6 12h12M2.25 7.5h19.5m-12 9h4.5"
      />
    </>
  ),
  flipboard: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M19.333 3.5H4.667c-.369 0-.667.415-.667.926v13.897c0 .512.298.927.667.927h14.666c.369 0 .667-.415.667-.927V4.426c0-.511-.299-.926-.667-.926Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M15 19.25 18 23m-9-3.75L6 23m6-19.5V1.25M4.667 3.5h14.666c.369 0 .667.415.667.926v13.897c0 .512-.299.927-.667.927H4.667c-.369 0-.667-.415-.667-.927V4.426c0-.511.298-.926.667-.926Z"
      />
    </>
  ),
  floor: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="m3 7.5 9 5.25 9-5.25-9-5.25L3 7.5Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="m3 16.5 9 5.25 9-5.25M3 12l9 5.25L21 12M3 7.5l9 5.25 9-5.25-9-5.25L3 7.5Z"
      />
    </>
  ),
  foodAndDrink: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M7.875 3V6.75M7.875 10.875V21M7.875 10.875C8.77011 10.875 9.62855 10.5194 10.2615 9.88649C10.8944 9.25355 11.25 8.39511 11.25 7.5L10.5 3M7.875 10.875C6.97989 10.875 6.12145 10.5194 5.48851 9.88649C4.85558 9.25355 4.5 8.39511 4.5 7.5L5.25 3M19.125 15H13.875C13.875 15 15 4.5 19.125 3V21"
      />
    </>
  ),
  fullScreen: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M15 4.5h4.5m0 0V9m0-4.5-5.25 5.25M9 19.5H4.5m0 0V15m0 4.5 5.25-5.25"
      />
    </>
  ),
  hamburger: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M3.75 12h12.5M3.75 6h16.5M3.75 18h16.5"
      />
    </>
  ),
  help: ({$color, $weight}: PathProps) => (
    <>
      <FilledPath
        as="circle"
        cx="11.25"
        cy="20.75"
        r={$weight === 'regular' ? '1.15' : $weight === 'light' ? '.85' : '.55'}
        $color={$color}
      />
      <Path
        d="M11.25 15.0001V14.1763C11.25 12.8462 12.0128 11.6339 13.2119 11.0583C15.9734 9.73283 15.776 5.7368 12.8973 4.69L12.5232 4.55397C10.3351 3.7583 7.97481 5.20161 7.68602 7.51191L7.5 9.00008M11.625 20.7501C11.625 20.9572 11.4571 21.1251 11.25 21.1251C11.0429 21.1251 10.875 20.9572 10.875 20.7501C10.875 20.543 11.0429 20.3751 11.25 20.3751C11.4571 20.3751 11.625 20.543 11.625 20.7501Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  home: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="m12 3.5-8 6.94V20h6v-6h4v6h6v-9.407L12 3.5Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M20.25 20.25v-9.422a.778.778 0 0 0-.244-.553l-7.5-6.816a.75.75 0 0 0-1.012 0l-7.5 6.816a.777.777 0 0 0-.244.553v9.422m-2.25 0h21m-8.25 0V15a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75v5.25"
      />
    </>
  ),
  info: ({$color, $weight}: PathProps) => (
    <>
      <FilledPath
        as="circle"
        cx="12"
        cy="5"
        r={$weight === 'regular' ? '1.25' : $weight === 'light' ? '1' : '.75'}
        $color={$color}
      />
      <Path
        d="M12 20.5V10.5"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  invisible: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="m4.5 3.75 15 16.5m-4.978-5.475A3.712 3.712 0 0 1 12 15.75a3.75 3.75 0 0 1-2.522-6.525m-2.54-2.794C3.112 8.362 1.5 12 1.5 12s3 6.75 10.5 6.75c1.757.014 3.493-.39 5.063-1.181m2.493-1.716C21.6 14.025 22.5 12 22.5 12s-3-6.75-10.5-6.75c-.65-.001-1.3.052-1.94.16m2.643 2.906a3.74 3.74 0 0 1 3.028 3.328"
      />
    </>
  ),
  link: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="m11.466 6.694 1.856-1.857a4.134 4.134 0 0 1 5.84 5.841l-2.653 2.644a4.114 4.114 0 0 1-5.83 0m1.855 3.984-1.856 1.856a4.135 4.135 0 0 1-5.84-5.84l2.653-2.644a4.114 4.114 0 0 1 5.83 0"
      />
    </>
  ),
  loader: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 3v3m9 6h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"
      />
    </>
  ),
  location: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M19.5 9.75c0 6.75-7.5 12-7.5 12s-7.5-5.25-7.5-12a7.5 7.5 0 0 1 15 0Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 12.75a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M19.5 9.75c0 6.75-7.5 12-7.5 12s-7.5-5.25-7.5-12a7.5 7.5 0 0 1 15 0Z"
      />
    </>
  ),
  locationUnknown: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75V9.75Z"
        $duotone={$duotone}
      />
      <Path
        d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M11.9546 11.8932V11.4638C11.9546 10.7705 12.3522 10.1386 12.9773 9.83862C14.4167 9.14771 14.3138 7.06483 12.8133 6.5192L12.6183 6.44829C11.4778 6.03356 10.2475 6.78587 10.097 7.99009L10 8.76578"
        $color={$color}
        $weight={$weight}
      />
      <FilledPath
        as="circle"
        cx="12"
        cy="15"
        r={$weight === 'regular' ? '1' : $weight === 'light' ? '.75' : '.6'}
        $color={$color}
      />
    </>
  ),
  lock: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M19.5 8.25h-15a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M8.625 8.25V4.875a3.375 3.375 0 0 1 6.75 0V8.25M4.5 8.25h15a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-.75.75h-15a.75.75 0 0 1-.75-.75V9a.75.75 0 0 1 .75-.75Z"
      />
    </>
  ),
  lockOpen: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M19.5 8.25h-15a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M8.625 8.25V4.875A3.375 3.375 0 0 1 15.26 4M4.5 8.25h15a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-.75.75h-15a.75.75 0 0 1-.75-.75V9a.75.75 0 0 1 .75-.75Z"
      />
    </>
  ),
  lounge: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M7.5 12.75H16.5"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M4.5 9V6.75C4.5 5.95435 4.81607 5.19129 5.37868 4.62868C5.94129 4.06607 6.70435 3.75 7.5 3.75H16.5C17.2956 3.75 18.0587 4.06607 18.6213 4.62868C19.1839 5.19129 19.5 5.95435 19.5 6.75V9"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M7.5 15.75V12C7.5 11.4067 7.32405 10.8266 6.99441 10.3333C6.66477 9.83994 6.19623 9.45543 5.64805 9.22836C5.09987 9.0013 4.49667 8.94189 3.91473 9.05765C3.33279 9.1734 2.79824 9.45912 2.37868 9.87868C1.95912 10.2982 1.6734 10.8328 1.55765 11.4147C1.44189 11.9967 1.5013 12.5999 1.72836 13.1481C1.95543 13.6962 2.33994 14.1648 2.83329 14.4944C3.32664 14.8241 3.90666 15 4.5 15V18.75C4.5 18.9489 4.57902 19.1397 4.71967 19.2803C4.86032 19.421 5.05109 19.5 5.25 19.5H18.75C18.9489 19.5 19.1397 19.421 19.2803 19.2803C19.421 19.1397 19.5 18.9489 19.5 18.75V15C20.0933 15 20.6734 14.8241 21.1667 14.4944C21.6601 14.1648 22.0446 13.6962 22.2716 13.1481C22.4987 12.5999 22.5581 11.9967 22.4424 11.4147C22.3266 10.8328 22.0409 10.2982 21.6213 9.87868C21.2018 9.45912 20.6672 9.1734 20.0853 9.05765C19.5033 8.94189 18.9001 9.0013 18.352 9.22836C17.8038 9.45543 17.3352 9.83994 17.0056 10.3333C16.6759 10.8266 16.5 11.4067 16.5 12V15.75"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  lunch: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M7.875 3V6.75M7.875 10.875V21M7.875 10.875C8.77011 10.875 9.62855 10.5194 10.2615 9.88649C10.8944 9.25355 11.25 8.39511 11.25 7.5L10.5 3M7.875 10.875C6.97989 10.875 6.12145 10.5194 5.48851 9.88649C4.85558 9.25355 4.5 8.39511 4.5 7.5L5.25 3M19.125 15H13.875C13.875 15 15 4.5 19.125 3V21"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  map: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="m9 17.25-6 1.5V5.25l6-1.5m6 16.5-6-3V3.75l6 3v13.5Zm0-13.5 6-1.5v13.5l-6 1.5"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="m9 17.25-6 1.5V5.25l6-1.5m0 13.5 6 3m-6-3V3.75m0 0 6 3m0 13.5V6.75m0 13.5 6-1.5V5.25l-6 1.5"
      />
    </>
  ),
  medical: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        d="M9 15H3.75C3.55109 15 3.36032 14.921 3.21967 14.7803C3.07902 14.6397 3 14.4489 3 14.25V9.75C3 9.55109 3.07902 9.36032 3.21967 9.21967C3.36032 9.07902 3.55109 9 3.75 9H9V3.75C9 3.55109 9.07902 3.36032 9.21967 3.21967C9.36032 3.07902 9.55109 3 9.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V9H20.25C20.4489 9 20.6397 9.07902 20.7803 9.21967C20.921 9.36032 21 9.55109 21 9.75V14.25C21 14.4489 20.921 14.6397 20.7803 14.7803C20.6397 14.921 20.4489 15 20.25 15H15V20.25C15 20.4489 14.921 20.6397 14.7803 20.7803C14.6397 20.921 14.4489 21 14.25 21H9.75C9.55109 21 9.36032 20.921 9.21967 20.7803C9.07902 20.6397 9 20.4489 9 20.25V15Z"
        $color={$color}
        $duotone={$duotone}
        $weight={$weight}
      />
    </>
  ),
  meetingRoom: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        d="M4.5 11.9999C4.5 9.51464 6.51472 7.49992 9 7.49992H15C17.4853 7.49992 19.5 9.51464 19.5 11.9999C19.5 14.4852 17.4853 16.4999 15 16.4999H9C6.51472 16.4999 4.5 14.4852 4.5 11.9999Z"
        $duotone={$duotone}
      />
      <Path
        d="M1.9 14C1.9 14 1.5 13 1.5 12C1.5 11 1.9 9.99997 1.9 9.99997M22.1 9.99997C22.1 9.99997 22.5 11 22.5 12C22.5 13 22.1 14 22.1 14M13.9907 4.61959C13.9907 4.61959 15.0367 4.36266 16.0269 4.50183C17.0172 4.641 17.9518 5.17628 17.9518 5.17628M10.0078 19.3804C10.0078 19.3804 8.96188 19.6373 7.97161 19.4981C6.98134 19.3589 6.04675 18.8237 6.04675 18.8237M6.04688 5.17623C6.04688 5.17623 6.98148 4.64095 7.97175 4.50178C8.96202 4.36261 10.008 4.61954 10.008 4.61954M17.9521 18.8237C17.9521 18.8237 17.0175 19.359 16.0273 19.4982C15.037 19.6373 13.9911 19.3804 13.9911 19.3804M9 16.4999H15C17.4853 16.4999 19.5 14.4852 19.5 11.9999C19.5 9.51464 17.4853 7.49992 15 7.49992H9C6.51472 7.49992 4.5 9.51464 4.5 11.9999C4.5 14.4852 6.51472 16.4999 9 16.4999Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  minus: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M3.75 12h16.5"
      />
    </>
  ),
  monitor: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M4.5 18h15a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5h-15A1.5 1.5 0 0 0 3 6v10.5A1.5 1.5 0 0 0 4.5 18Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M15 21H9m10.5-3h-15A1.5 1.5 0 0 1 3 16.5V6a1.5 1.5 0 0 1 1.5-1.5h15A1.5 1.5 0 0 1 21 6v10.5a1.5 1.5 0 0 1-1.5 1.5Z"
      />
    </>
  ),
  noBroadcast: ({$color, $weight}: PathProps) => {
    const randomId = generateRandomId();
    return (
      <>
        <defs>
          <mask id={`noBroadcast-${randomId}`}>
            <rect
              width="100%"
              height="100%"
              fill="black"
            />

            <path
              d="M7.22812 16.7718C6.61221 16.1566 6.12185 15.4274 5.78437 14.6249C5.43162 13.7947 5.24982 12.902 5.24982 11.9999C5.24982 11.0979 5.43162 10.2051 5.78437 9.37493C6.12185 8.57246 6.61221 7.84329 7.22812 7.22806M16.7719 7.22806C17.3878 7.84329 17.8782 8.57246 18.2157 9.37493C18.5684 10.2051 18.7502 11.0979 18.7502 11.9999C18.7502 12.902 18.5684 13.7947 18.2157 14.6249C17.8782 15.4274 17.3878 16.1566 16.7719 16.7718M4.57507 19.425C3.6185 18.4666 2.85469 17.3336 2.32507 16.0875C1.78052 14.7935 1.5 13.4038 1.5 12C1.5 10.5961 1.78052 9.20639 2.32507 7.91245C2.85469 6.66627 3.6185 5.53329 4.57507 4.57495M19.4249 4.57495C20.3815 5.53329 21.1453 6.66627 21.6749 7.91245C22.2195 9.20639 22.5 10.5961 22.5 12C22.5 13.4038 22.2195 14.7935 21.6749 16.0875C21.1453 17.3336 20.3815 18.4666 19.4249 19.425M15 12C15 13.6568 13.6569 15 12 15C10.3431 15 9 13.6568 9 12C9 10.3431 10.3431 8.99997 12 8.99997C13.6569 8.99997 15 10.3431 15 12Z"
              stroke="white"
              strokeWidth={$weight === 'regular' ? '2' : $weight === 'light' ? '1.5' : '1'}
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="black"
            />
            <path
              d="M4 21L20 3"
              stroke="black"
              strokeWidth="4.5"
            />
            <path
              d="M5 20L19 4"
              stroke="white"
              strokeWidth={$weight === 'regular' ? '2' : $weight === 'light' ? '1.5' : '1'}
              strokeLinecap="round"
            />
          </mask>
        </defs>

        <Rect
          width="100%"
          height="100%"
          $color={$color}
          mask={`url(#noBroadcast-${randomId})`}
        />
      </>
    );
  },
  noCalendar: ({$color, $weight, $duotone}: PathProps) => {
    const randomId = generateRandomId();
    return (
      <>
        <defs>
          <mask id={`noCalendar-${randomId}`}>
            <path
              d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
              fill="black"
              stroke="black"
              strokeWidth={$weight === 'regular' ? '2' : $weight === 'light' ? '1.5' : '1'}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 2.25V5.25M7.5 2.25V5.25M3.75 8.25H20.25M4.5 3.75H19.5C19.9142 3.75 20.25 4.08579 20.25 4.5V19.5C20.25 19.9142 19.9142 20.25 19.5 20.25H4.5C4.08579 20.25 3.75 19.9142 3.75 19.5V4.5C3.75 4.08579 4.08579 3.75 4.5 3.75Z"
              stroke="white"
              strokeWidth={$weight === 'regular' ? '2' : $weight === 'light' ? '1.5' : '1'}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 21L21 3"
              stroke="black"
              strokeWidth="4.5"
            />
            <path
              d="M3 21L21 3"
              stroke="white"
              strokeWidth={$weight === 'regular' ? '2' : $weight === 'light' ? '1.5' : '1'}
              strokeLinecap="round"
            />
          </mask>
        </defs>
        <DuotonePath
          d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
          $duotone={$duotone}
          $color={$duotone ? 'white' : 'none'}
          $weight={$weight}
        />
        <Rect
          width="100%"
          height="100%"
          $color={$color}
          fill="red"
          mask={`url(#noCalendar-${randomId})`}
        />
      </>
    );
  },
  office: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        $color="none"
        d="M3 3h10v6.5h8V20H3V3Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M13.5 20.25V3.75a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75v16.5m-1.5 0h21m-1.5 0V9.75a.75.75 0 0 0-.75-.75H13.5M6 6.75h3m-1.5 6h3M6 16.5h3m7.5 0H18m-1.5-3.75H18"
      />
    </>
  ),
  openInBrowser: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M20.25 9.375V3.75M20.25 3.75H14.625M20.25 3.75L13.5 10.5M17.25 14.5V19.5C17.25 19.6989 17.171 19.8897 17.0303 20.0303C16.8897 20.171 16.6989 20.25 16.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V7.5C3.75 7.30109 3.82902 7.11032 3.96967 6.96967C4.11032 6.82902 4.30109 6.75 4.5 6.75H9.5"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  parking: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M3 4a1 1 0 0 1 1-1h16.5a1 1 0 0 1 1 1v16.5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4Z"
      />
      <DuotonePath
        $duotone={$duotone}
        d="M13 7H9v6h4a3 3 0 1 0 0-6Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M9 17.5V13m0 0V7h4a3 3 0 1 1 0 6H9Zm-5 8.5h16.5a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v16.5a1 1 0 0 0 1 1Z"
      />
    </>
  ),
  pencil: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M8.69 20.25H4.5a.75.75 0 0 1-.75-.75v-4.19a.74.74 0 0 1 .216-.526l11.25-11.25a.75.75 0 0 1 1.068 0l4.182 4.182a.75.75 0 0 1 0 1.068l-11.25 11.25a.74.74 0 0 1-.525.216Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M12.75 6 18 11.25m-9.31 9H4.5a.75.75 0 0 1-.75-.75v-4.19a.74.74 0 0 1 .216-.526l11.25-11.25a.75.75 0 0 1 1.068 0l4.182 4.182a.75.75 0 0 1 0 1.068l-11.25 11.25a.74.74 0 0 1-.525.216Z"
      />
    </>
  ),
  play: ({$color, $duotone, $weight}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        $color={$color}
        $weight={$weight}
        d="m21.384 11.363-13.49-8.25a.75.75 0 0 0-1.144.637v16.5a.75.75 0 0 0 1.144.637l13.49-8.25a.74.74 0 0 0 0-1.274Z"
      />
    </>
  ),
  plus: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M3.75 12h16.5M12 3.75v16.5"
      />
    </>
  ),
  presentationScreen: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M20.25 4.5H3.75a.75.75 0 0 0-.75.75V16.5c0 .414.336.75.75.75h16.5a.75.75 0 0 0 .75-.75V5.25a.75.75 0 0 0-.75-.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M15 17.25 18 21m-9-3.75L6 21m3-9.75v2.25m3-3.75v3.75m3-5.25v5.25m-3-9V2.25M3.75 4.5h16.5a.75.75 0 0 1 .75.75V16.5a.75.75 0 0 1-.75.75H3.75A.75.75 0 0 1 3 16.5V5.25a.75.75 0 0 1 .75-.75Z"
      />
    </>
  ),
  profile: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 15a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Zm0 0a6.75 6.75 0 0 0-6.019 3.694M12 15a6.749 6.749 0 0 1 6.019 3.694M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </>
  ),
  projector: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M2 8a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10H2V8Z"
      />
      <DuotonePath
        $duotone={$duotone}
        d="M11 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm8-2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M4 18v1.5M20 18v1.5m2-1.5V8a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10h20Zm-11-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm8-2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      />
    </>
  ),
  reception: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M12.052 13a3.75 3.75 0 1 0-.104 0 6.751 6.751 0 0 0-3.479.997C7.407 14.649 6 16.5 6 19h12c0-2.5-1.407-4.351-2.469-5.003a6.751 6.751 0 0 0-3.48-.997Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 13a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Zm0 0a6.75 6.75 0 0 0-3.531.997C7.407 14.649 6 16.5 6 19m6-6a6.75 6.75 0 0 1 3.531.997C16.593 14.649 18 16.5 18 19M2 19h20"
      />
    </>
  ),
  refresh: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M16.519 9.347h4.5m0 0v-4.5m0 4.5L17.83 6.169a8.24 8.24 0 0 0-11.662 0m1.312 8.484h-4.5m0 0v4.5m0-4.5 3.188 3.178a8.24 8.24 0 0 0 11.662 0"
      />
    </>
  ),
  remote: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        d="M4.5 18L19.5 18C20.3284 18 21 17.3284 21 16.5V6C21 5.17157 20.3284 4.5 19.5 4.5L4.5 4.5C3.67157 4.5 3 5.17157 3 6V16.5C3 17.3284 3.67157 18 4.5 18Z"
        $duotone={$duotone}
      />
      <Path
        d="M15 21H9M7 18C8 13.5 16 13.5 17 18M19.5 18H4.5C3.67157 18 3 17.3284 3 16.5V6C3 5.17157 3.67157 4.5 4.5 4.5H19.5C20.3284 4.5 21 5.17157 21 6V16.5C21 17.3284 20.3284 18 19.5 18ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  reportProblem: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        d="M12.0001 9.74988V13.4999M10.7063 3.74975L2.45634 17.9997C2.32491 18.2274 2.2556 18.4856 2.25537 18.7484C2.25514 19.0113 2.32398 19.2696 2.45501 19.4975C2.58604 19.7253 2.77465 19.9148 3.00194 20.0468C3.22923 20.1788 3.48723 20.2488 3.75009 20.2497H20.2501C20.5129 20.2488 20.7709 20.1788 20.9982 20.0468C21.2255 19.9148 21.4141 19.7253 21.5452 19.4975C21.6762 19.2696 21.745 19.0113 21.7448 18.7484C21.7446 18.4856 21.6753 18.2274 21.5438 17.9997L13.2938 3.74975C13.1634 3.52181 12.975 3.33238 12.7479 3.20064C12.5207 3.0689 12.2627 2.99951 12.0001 2.99951C11.7375 2.99951 11.4795 3.0689 11.2523 3.20064C11.0251 3.33238 10.8368 3.52181 10.7063 3.74975Z"
        $color={$color}
        $duotone={$duotone}
        $weight={$weight}
      />
      <FilledPath
        d="M12 17.8125C12.5178 17.8125 12.9375 17.3928 12.9375 16.875C12.9375 16.3572 12.5178 15.9375 12 15.9375C11.4822 15.9375 11.0625 16.3572 11.0625 16.875C11.0625 17.3928 11.4822 17.8125 12 17.8125Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  room: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M7 3h10.5v18H7z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M5 21h14.5m-6-9H15m-8 9V3.75a.87.87 0 0 1 .17-.53.53.53 0 0 1 .413-.22h9.334c.154 0 .303.079.412.22.11.14.171.331.171.53V21"
      />
    </>
  ),
  search: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M10.875 18.75a7.875 7.875 0 1 0 0-15.75 7.875 7.875 0 0 0 0 15.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M16.444 16.444 21 21m-2.25-10.125a7.875 7.875 0 1 1-15.75 0 7.875 7.875 0 0 1 15.75 0Z"
      />
    </>
  ),
  settings: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M17.222 6.103c.237.219.462.444.675.675l2.56.366c.416.724.738 1.5.955 2.306l-1.556 2.072s.028.637 0 .956l1.556 2.072a9.585 9.585 0 0 1-.956 2.306l-2.56.366s-.44.46-.674.675l-.366 2.56c-.724.416-1.5.738-2.306.955l-2.072-1.556a5.434 5.434 0 0 1-.956 0L9.45 21.412a9.584 9.584 0 0 1-2.306-.956l-.366-2.56a17.255 17.255 0 0 1-.675-.674l-2.56-.366a9.677 9.677 0 0 1-.956-2.306l1.557-2.072s-.028-.637 0-.956L2.587 9.45a9.582 9.582 0 0 1 .957-2.306l2.559-.366c.219-.231.444-.456.675-.675l.366-2.56a9.676 9.676 0 0 1 2.306-.956l2.072 1.557a5.45 5.45 0 0 1 .956 0l2.072-1.557a9.582 9.582 0 0 1 2.306.957l.366 2.559Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        fillRule="evenodd"
        d="M17.897 6.778a11.46 11.46 0 0 0-.675-.675l-.366-2.56a9.582 9.582 0 0 0-2.306-.955l-2.072 1.556a5.429 5.429 0 0 0-.956 0L9.45 2.588a9.676 9.676 0 0 0-2.306.956l-.366 2.56a17.16 17.16 0 0 0-.675.674l-2.56.366a9.59 9.59 0 0 0-.955 2.306l1.556 2.072c-.028.319 0 .956 0 .956L2.588 14.55c.217.807.539 1.582.956 2.306l2.56.366c.218.231.443.456.674.675l.366 2.56a9.633 9.633 0 0 0 2.306.956l2.072-1.557c.318.028.638.028.956 0l2.072 1.556a9.677 9.677 0 0 0 2.306-.956l.366-2.56c.234-.215.675-.674.675-.674l2.56-.366a9.633 9.633 0 0 0 .956-2.306l-1.557-2.072c.028-.319 0-.956 0-.956l1.556-2.072a9.675 9.675 0 0 0-.956-2.306l-2.56-.366ZM16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
        clipRule="evenodd"
      />
    </>
  ),
  share: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M8.063 5.438 12 1.5m0 0 3.938 3.938M12 1.5V12m4.5-3h2.25a.75.75 0 0 1 .75.75v9.75a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75V9.75A.75.75 0 0 1 5.25 9H7.5"
      />
    </>
  ),
  shareAndroid: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M16.5 8.25a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm13.5 3.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="m13.978 6.872-5.456 3.506m0 3.244 5.456 3.506M9 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm10.5 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm0-13.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </>
  ),
  speaker: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M7.5 15.75H3a.75.75 0 0 1-.75-.75V9A.75.75 0 0 1 3 8.25h4.5L14.25 3v18L7.5 15.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M20.522 7.228a6.74 6.74 0 0 1 0 9.544M7.5 15.75H3a.75.75 0 0 1-.75-.75V9A.75.75 0 0 1 3 8.25h4.5m0 7.5L14.25 21V3L7.5 8.25m0 7.5v-7.5m10.369 1.631a2.99 2.99 0 0 1 0 4.238"
      />
    </>
  ),
  speakerphone: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M6 15.75H1.5A.75.75 0 0 1 .75 15V9a.75.75 0 0 1 .75-.75H6L12.75 3v18L6 15.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M6 15.75H1.5A.75.75 0 0 1 .75 15V9a.75.75 0 0 1 .75-.75H6m0 7.5L12.75 21V3L6 8.25m0 7.5v-7.5M19.5 12 23 9.375 19.5 6.75V12Zm0 0 3.5 2.625-3.5 2.625V12Zm0 0L16 9.375M19.5 12 16 14.625"
      />
    </>
  ),
  star: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="m12.412 17.878 4.725 3c.61.384 1.36-.187 1.182-.89l-1.369-5.382a.816.816 0 0 1 .272-.825l4.237-3.534c.553-.46.272-1.388-.45-1.434l-5.53-.357a.778.778 0 0 1-.685-.506L12.73 2.756a.778.778 0 0 0-1.462 0L9.206 7.95a.778.778 0 0 1-.684.506l-5.531.357c-.722.046-1.004.975-.45 1.434l4.237 3.534a.815.815 0 0 1 .272.825l-1.266 4.988c-.215.843.685 1.528 1.407 1.069l4.397-2.785a.769.769 0 0 1 .825 0Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="m12.412 17.878 4.725 3c.61.384 1.36-.187 1.182-.89l-1.369-5.382a.816.816 0 0 1 .272-.825l4.237-3.534c.553-.46.272-1.388-.45-1.434l-5.53-.357a.778.778 0 0 1-.685-.506L12.73 2.756a.778.778 0 0 0-1.462 0L9.206 7.95a.778.778 0 0 1-.684.506l-5.531.357c-.722.046-1.004.975-.45 1.434l4.237 3.534a.815.815 0 0 1 .272.825l-1.266 4.988c-.215.843.685 1.528 1.407 1.069l4.397-2.785a.769.769 0 0 1 .825 0Z"
      />
    </>
  ),
  stairs: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M2 18.75h5v-4.5h5v-4.5h5v-4.5h5"
      />
    </>
  ),
  storage: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M21 16.622V7.378a.759.759 0 0 0-.384-.656l-8.25-4.64a.73.73 0 0 0-.732 0l-8.25 4.64A.76.76 0 0 0 3 7.378v9.244a.76.76 0 0 0 .384.656l8.25 4.64a.73.73 0 0 0 .732 0l8.25-4.64a.76.76 0 0 0 .384-.656Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M20.897 6.994 12.084 12m0 0-8.98-5.006M12.083 12 12 22.012m9-5.39V7.378a.759.759 0 0 0-.384-.656l-8.25-4.64a.73.73 0 0 0-.732 0l-8.25 4.64A.76.76 0 0 0 3 7.378v9.244a.76.76 0 0 0 .384.656l8.25 4.64a.73.73 0 0 0 .732 0l8.25-4.64a.76.76 0 0 0 .384-.656Z"
      />
    </>
  ),
  support: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        $color={$color}
        $duotone={$duotone}
        $weight={$weight}
      />
      <Path
        d="M11.9546 13.0598V12.6305C11.9546 11.9371 12.3522 11.3053 12.9773 11.0052C14.4167 10.3143 14.3138 8.23146 12.8133 7.68582L12.6183 7.61492C11.4778 7.20018 10.2475 7.9525 10.097 9.15671L10 9.9324"
        $color={$color}
        $weight={$weight}
      />
      <FilledPath
        as="circle"
        cy="16"
        cx="12"
        r={$weight === 'regular' ? '1.1' : $weight === 'light' ? '.85' : '.6'}
        $color={$color}
      />
    </>
  ),
  toilet: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M6 10.5V3.75A.75.75 0 0 1 6.75 3h10.5a.75.75 0 0 1 .75.75v6.75h2.25a8.25 8.25 0 0 1-5.025 7.594l.403 2.803a.75.75 0 0 1-.74.853H9.111a.749.749 0 0 1-.74-.853l.403-2.803A8.246 8.246 0 0 1 3.75 10.5H6Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M6 10.5V3.75A.75.75 0 0 1 6.75 3h10.5a.75.75 0 0 1 .75.75v6.75M9 6h1.5M8.775 18.094l-.403 2.803a.75.75 0 0 0 .74.853h5.776a.749.749 0 0 0 .74-.853l-.403-2.803M20.25 10.5a8.25 8.25 0 1 1-16.5 0h16.5Z"
      />
    </>
  ),
  trash: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M18.75 5.25V19.5a.75.75 0 0 1-.75.75H6a.75.75 0 0 1-.75-.75V5.25m10.5 0v-1.5a1.5 1.5 0 0 0-1.5-1.5h-4.5a1.5 1.5 0 0 0-1.5 1.5v1.5"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M20.25 5.25H3.75m6 4.5v6m4.5-6v6m4.5-10.5V19.5a.75.75 0 0 1-.75.75H6a.75.75 0 0 1-.75-.75V5.25m10.5 0v-1.5a1.5 1.5 0 0 0-1.5-1.5h-4.5a1.5 1.5 0 0 0-1.5 1.5v1.5"
      />
    </>
  ),
  undo: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M7.4812 9.34689H2.9812M2.9812 9.34689V4.84689M2.9812 9.34689L6.1687 6.16875C7.32222 5.0143 8.79221 4.22789 10.3927 3.90902C11.9933 3.59014 13.6524 3.75313 15.1603 4.37735C16.6682 5.00158 17.957 6.059 18.8639 7.41585C19.7707 8.7727 20.2547 10.368 20.2547 12C20.2547 13.632 19.7707 15.2273 18.8639 16.5842C17.957 17.941 16.6682 18.9984 15.1603 19.6227C13.6524 20.2469 11.9933 20.4099 10.3927 20.091C8.79221 19.7721 7.32222 18.9857 6.1687 17.8313"
      />
    </>
  ),
  user: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15ZM12 15C10.1567 15 8.34588 15.4844 6.74958 16.4062C5.15328 17.3279 3.82775 18.6536 2.90625 20.25M12 15C13.8433 15 15.6541 15.4844 17.2504 16.4062C18.8467 17.3279 20.1722 18.6536 21.0938 20.25"
      />
    </>
  ),
  users: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M8.25 15a4.875 4.875 0 1 0 0-9.75 4.875 4.875 0 0 0 0 9.75Zm0 0a8.25 8.25 0 0 0-6.75 3.506M8.25 15A8.25 8.25 0 0 1 15 18.506m-.431-13.078a5.11 5.11 0 0 1 1.322-.178 4.875 4.875 0 0 1 0 9.75 8.242 8.242 0 0 1 6.75 3.506"
      />
    </>
  ),
  videocamera: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M2.25 5.625h12a3 3 0 0 1 3 3v9a.75.75 0 0 1-.75.75h-12a3 3 0 0 1-3-3v-9a.75.75 0 0 1 .75-.75Zm15 4.875 5.25-3v9l-5.25-3"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="m17.25 10.5 5.25-3v9l-5.25-3m-15-7.875h12a3 3 0 0 1 3 3v9a.75.75 0 0 1-.75.75h-12a3 3 0 0 1-3-3v-9a.75.75 0 0 1 .75-.75Z"
      />
    </>
  ),
  visible: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M12 5.25C4.5 5.25 1.5 12 1.5 12s3 6.75 10.5 6.75S22.5 12 22.5 12s-3-6.75-10.5-6.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 5.25C4.5 5.25 1.5 12 1.5 12s3 6.75 10.5 6.75S22.5 12 22.5 12s-3-6.75-10.5-6.75Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
      />
    </>
  ),
  visitors: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M18.7498 12.75H23.2498M20.9998 10.5V15M2.08105 18.75C3.06661 17.5753 4.29746 16.6308 5.68711 15.9828C7.07676 15.3347 8.59148 14.9989 10.1248 14.9989C11.6581 14.9989 13.1728 15.3347 14.5625 15.9828C15.9521 16.6308 17.183 17.5753 18.1686 18.75M15.7498 9.375C15.7498 12.4816 13.2314 15 10.1248 15C7.01818 15 4.49979 12.4816 4.49979 9.375C4.49979 6.2684 7.01818 3.75 10.1248 3.75C13.2314 3.75 15.7498 6.2684 15.7498 9.375Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  warning: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M12 4.5L12 14.5"
        $color={$color}
        $weight={$weight}
      />
      <FilledPath
        as="circle"
        cx="12"
        cy="20"
        r={$weight === 'regular' ? '1.25' : $weight === 'light' ? '1' : '.75'}
        $color={$color}
      />
    </>
  ),
  webcam: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M12 16.5A6.75 6.75 0 1 0 12 3a6.75 6.75 0 0 0 0 13.5Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M12 16.5A6.75 6.75 0 1 0 12 3a6.75 6.75 0 0 0 0 13.5Zm0 0v3m-9 0h18m-6-9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </>
  ),
  weekView: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M3 5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5V9C21 9.55228 20.5523 10 20 10H4C3.44772 10 3 9.55228 3 9V5Z"
      />
      <DuotonePath
        $duotone={$duotone}
        d="M3 15C3 14.4477 3.44772 14 4 14H20C20.5523 14 21 14.4477 21 15V19C21 19.5523 20.5523 20 20 20H4C3.44772 20 3 19.5523 3 19V15Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M3 5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5V9C21 9.55228 20.5523 10 20 10H4C3.44772 10 3 9.55228 3 9V5Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M3 15C3 14.4477 3.44772 14 4 14H20C20.5523 14 21 14.4477 21 15V19C21 19.5523 20.5523 20 20 20H4C3.44772 20 3 19.5523 3 19V15Z"
      />
    </>
  ),
  whiteboard: ({$color, $weight}: PathProps) => (
    <>
      <Path
        $color={$color}
        $weight={$weight}
        d="M3 15.75V5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 .75.75v10.5m-19.5 3h21m-11.25 0v-3h6v3"
      />
    </>
  ),
  whiteboardInteractive: ({$color, $weight, $duotone}: PathProps) => (
    <>
      <DuotonePath
        $duotone={$duotone}
        d="M5.006 19.5a5.25 5.25 0 0 1 9.488 0h5.756a.75.75 0 0 0 .75-.75V5.25a.75.75 0 0 0-.75-.75H3.75a.75.75 0 0 0-.75.75v13.5a.75.75 0 0 0 .75.75h1.256Z"
      />
      <Path
        $color={$color}
        $weight={$weight}
        d="M9.75 16.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 0a5.25 5.25 0 0 0-4.744 3H3.75a.75.75 0 0 1-.75-.75V5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75h-5.756a5.251 5.251 0 0 0-4.744-3Zm6.75 0H18v-9H6V9"
      />
    </>
  ),
  mouse: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M13.5 2.25H10.5C7.60051 2.25 5.25 4.60051 5.25 7.5V16.5C5.25 19.3995 7.60051 21.75 10.5 21.75H13.5C16.3995 21.75 18.75 19.3995 18.75 16.5V7.5C18.75 4.60051 16.3995 2.25 13.5 2.25Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M12 10.5V6"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  keyboard: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M20.9541 5.25H3.04594C2.60635 5.25 2.25 5.60635 2.25 6.04594V17.9541C2.25 18.3936 2.60635 18.75 3.04594 18.75H20.9541C21.3936 18.75 21.75 18.3936 21.75 17.9541V6.04594C21.75 5.60635 21.3936 5.25 20.9541 5.25Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M5.25 12H18.75"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M5.25 9H18.75"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M5.25 15H6"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M9 15H15"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M18 15H18.75"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  laptop: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M3.75 16.5V6.75C3.75 6.35218 3.90804 5.97064 4.18934 5.68934C4.47064 5.40804 4.85218 5.25 5.25 5.25H18.75C19.1478 5.25 19.5294 5.40804 19.8107 5.68934C20.092 5.97064 20.25 6.35218 20.25 6.75V16.5"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M2.25 16.5H21.75V18C21.75 18.3978 21.592 18.7794 21.3107 19.0607C21.0294 19.342 20.6478 19.5 20.25 19.5H3.75C3.35218 19.5 2.97064 19.342 2.68934 19.0607C2.40804 18.7794 2.25 18.3978 2.25 18V16.5Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M13.5 8.25H10.5"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  plugs: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M13.5 13.5L11.25 15.75"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M10.5 10.5L8.25 12.75"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M6 10.5L13.5 18"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M5.44313 18.5569L2.25 21.75"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M12.375 16.875L9.65621 19.5938C9.23426 20.0157 8.66198 20.2527 8.06527 20.2527C7.46856 20.2527 6.89629 20.0157 6.47433 19.5938L4.40621 17.5228C3.98514 17.101 3.74866 16.5293 3.74866 15.9333C3.74866 15.3373 3.98514 14.7656 4.40621 14.3438L7.12496 11.625"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M10.5 6L18 13.5"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M18.5569 5.44313L21.75 2.25"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M16.875 12.375L19.5938 9.65625C20.0157 9.23429 20.2527 8.66202 20.2527 8.06531C20.2527 7.4686 20.0157 6.89632 19.5938 6.47437L17.5228 4.40625C17.101 3.98518 16.5293 3.7487 15.9333 3.7487C15.3373 3.7487 14.7656 3.98518 14.3438 4.40625L11.625 7.125"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  dualMonitor: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M16.5 6.925V5.125C16.5 4.50368 15.9963 4 15.375 4L4.125 4C3.50368 4 3 4.50368 3 5.125L3 13C3 13.6213 3.50368 14.125 4.125 14.125H6.73975"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M8.625 17.7249H19.875C20.4963 17.7249 21 17.2212 21 16.5999V8.72485C21 8.10353 20.4963 7.59985 19.875 7.59985L8.625 7.59985C8.00368 7.59985 7.5 8.10353 7.5 8.72485L7.5 16.5999C7.5 17.2212 8.00368 17.7249 8.625 17.7249Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M16.5 19.9751H12"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  headset: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M21 17.75V18.5C21 19.2956 20.6839 20.0587 20.1213 20.6213C19.5587 21.1839 18.7956 21.5 18 21.5H12.75"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M21 11H18C17.6022 11 17.2206 11.158 16.9393 11.4393C16.658 11.7206 16.5 12.1022 16.5 12.5V16.25C16.5 16.6478 16.658 17.0294 16.9393 17.3107C17.2206 17.592 17.6022 17.75 18 17.75H21V11ZM21 11C21 9.8181 20.7672 8.64778 20.3149 7.55585C19.8626 6.46392 19.1997 5.47177 18.364 4.63604C17.5282 3.80031 16.5361 3.13738 15.4442 2.68508C14.3522 2.23279 13.1819 2 12 2C10.8181 2 9.64778 2.23279 8.55585 2.68508C7.46392 3.13738 6.47177 3.80031 5.63604 4.63604C4.80031 5.47177 4.13738 6.46392 3.68508 7.55585C3.23279 8.64778 3 9.8181 3 11M3 11V16.25C3 16.6478 3.15804 17.0294 3.43934 17.3107C3.72064 17.592 4.10218 17.75 4.5 17.75H6C6.39782 17.75 6.77936 17.592 7.06066 17.3107C7.34196 17.0294 7.5 16.6478 7.5 16.25V12.5C7.5 12.1022 7.34196 11.7206 7.06066 11.4393C6.77936 11.158 6.39782 11 6 11H3Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  wheelchair: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M8.75 6.75V12.75H17L20 18.75L22.25 18"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M8.75 6.75C9.99264 6.75 11 5.74264 11 4.5C11 3.25736 9.99264 2.25 8.75 2.25C7.50736 2.25 6.5 3.25736 6.5 4.5C6.5 5.74264 7.50736 6.75 8.75 6.75Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M14.75 9H9.5C7.9087 9 6.38258 9.63214 5.25736 10.7574C4.13214 11.8826 3.5 13.4087 3.5 15C3.5 16.5913 4.13214 18.1174 5.25736 19.2426C6.38258 20.3679 7.9087 21 9.5 21C12.2956 21 14.8344 19.0875 15.5 16.5"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  accessibility: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M11.9996 6.5C13.2423 6.5 14.2496 5.49264 14.2496 4.25C14.2496 3.00736 13.2423 2 11.9996 2C10.757 2 9.74963 3.00736 9.74963 4.25C9.74963 5.49264 10.757 6.5 11.9996 6.5Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M3.65592 10.1469C2.55998 9.64063 2.91998 8 4.12467 8H19.8747C21.0812 8 21.4412 9.64063 20.3434 10.1469L14.9997 12.5L17.1437 20.6497C17.2648 20.9193 17.2748 21.2258 17.1717 21.5028C17.0686 21.7798 16.8607 22.0052 16.5928 22.13C16.3249 22.2549 16.0186 22.2694 15.7401 22.1703C15.4616 22.0711 15.2334 21.8664 15.1047 21.6003L11.9997 16.25L8.89467 21.6003C8.76595 21.8664 8.53769 22.0711 8.25922 22.1703C7.98076 22.2694 7.67447 22.2549 7.40657 22.13C7.13868 22.0052 6.9307 21.7798 6.82759 21.5028C6.72449 21.2258 6.73455 20.9193 6.8556 20.6497L8.99967 12.5L3.65592 10.1469Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
  megaPhone: ({$color, $weight}: PathProps) => (
    <>
      <Path
        d="M5.25 15L6.49125 19.6809C6.52035 19.7976 6.57706 19.9055 6.65661 19.9957C6.73616 20.0858 6.83621 20.1555 6.94833 20.1989C7.06046 20.2423 7.18137 20.2581 7.30087 20.2449C7.42037 20.2318 7.53497 20.1901 7.635 20.1234L8.66625 19.4363C8.76886 19.3678 8.853 19.2751 8.91121 19.1663C8.96943 19.0576 8.99993 18.9362 9 18.8128V14.9999L5.25 15Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M5.25 14.9999H9V7.49988H5.25C4.25544 7.49988 3.30161 7.89497 2.59835 8.59823C1.89509 9.30149 1.5 10.2553 1.5 11.2499C1.5 12.2444 1.89509 13.1983 2.59835 13.9015C3.30161 14.6048 4.25544 14.9999 5.25 14.9999Z"
        $color={$color}
        $weight={$weight}
      />
      <Path
        d="M20.1323 19.153C20.2091 19.0325 20.25 18.8927 20.25 18.7499V3.74986C20.2498 3.60711 20.2088 3.46739 20.132 3.3471C20.0551 3.22681 19.9455 3.13096 19.816 3.07081C19.6866 3.01066 19.5426 2.9887 19.4011 3.00752C19.2596 3.02634 19.1264 3.08515 19.0172 3.17705C14.1047 7.29736 9 7.49986 9 7.49986V14.9999C9 14.9999 14.1047 15.2024 19.0172 19.3236C19.1265 19.4156 19.2598 19.4744 19.4014 19.4932C19.543 19.512 19.687 19.4899 19.8165 19.4297C19.946 19.3694 20.0555 19.2734 20.1323 19.153Z"
        $color={$color}
        $weight={$weight}
      />
    </>
  ),
} as const;
